<app-breadcrumbs></app-breadcrumbs>
<div class="admin-container">
  <div class="admin-content admin-content--tinny">
    <div class="user-profile">
      <div class="admin-page-title">
        {{ userName | translate }}
        <div class="admin-page-title__actions" *ngIf="!isOwnProfile && (user.status === 3 || user.status === 5)">
          <button (click)="archiveEntity()"
                  [class.inactive]="archiving"
                  [class.erom-spinner]="archiving"
                  [disabled]="archiving"
                  *ngIf="user.status === 3">
            <fa-icon [icon]="['fas', 'folder']"></fa-icon>{{ 'profile.ARCHIVE_USER' | translate }}
          </button>
          <button (click)="restoreEntity()"
                  [class.inactive]="archiving"
                  [class.erom-spinner]="archiving"
                  [disabled]="archiving"
                  *ngIf="user.status === 5">
            <fa-icon [icon]="['fas', 'redo']"></fa-icon>{{ 'profile.RESTORE_USER' | translate }}
          </button>
        </div>
      </div>
      <div class="user-profile__meta">
        <span>{{ 'profile.JOINED' | translate : { date: user.createdOn } }}</span>
        <ng-container *ngIf="user.modifiedOn">
          <span class="user-profile__meta--separator">|</span>
          <span>{{ 'profile.LAST_UPDATED' | translate : { date: user.modifiedOn } }}</span>
        </ng-container>
      </div>
      <div class="admin-content__section-group">
        <div class="admin-content__section no-paddings" [class.inactive]="activeSection !== 'account-data'">
          <div class="admin-content__section--header" [class.inactive]="activeSection !== 'account-data'">
            <h2>{{ 'profile.ACCOUNT_DATA' | translate }}</h2>
            <button *ngIf="activeSection !== 'account-data'" (click)="activateSection('account-data')">
              <fa-icon [icon]="['fas', 'pen']"></fa-icon>
              <span>{{ 'profile.EDIT' | translate }}</span>
            </button>
          </div>
          <div class="admin-content__section--content">
            <ng-container *ngIf="activeSection !== 'account-data'; else activeAccountData">
              <div class="user-profile__data-preview">
                <div class="user-profile__data-preview--column">
                  <div class="user-profile__data-preview--column__label">{{ 'profile.FIRST_NAME' | translate }}</div>
                  <div class="user-profile__data-preview--column__value">{{ user.name || 'n/a' }}</div>
                </div>
                <div class="user-profile__data-preview--column">
                  <div class="user-profile__data-preview--column__label">{{ 'profile.LAST_NAME' | translate }}</div>
                  <div class="user-profile__data-preview--column__value">{{ user.surname || 'n/a' }}</div>
                </div>
                <div class="user-profile__data-preview--column">
                  <div class="user-profile__data-preview--column__label">{{ 'profile.EMAIL' | translate }}</div>
                  <div class="user-profile__data-preview--column__value">{{ user.email || 'n/a' }}</div>
                </div>
              </div>
            </ng-container>
            <ng-template #activeAccountData>
              <form [formGroup]="accountFormData" (ngSubmit)="saveAccountData()">
                <div class="user-profile__data-form">
                  <div class="form-group">
                    <label for="name" class="form-label">{{ 'profile.FIRST_NAME' | translate }}</label>
                    <input type="text"
                           id="name"
                           formControlName="name"
                           [placeholder]="'admin.TYPE_IN' | translate"
                           data-lpignore="true"
                           class="form-control form-control__input" />
                  </div>
                  <div class="form-group">
                    <label for="surname" class="form-label">{{ 'profile.LAST_NAME' | translate }}</label>
                    <input type="text"
                           id="surname"
                           formControlName="surname"
                           [placeholder]="'admin.TYPE_IN' | translate"
                           data-lpignore="true"
                           class="form-control form-control__input" />
                  </div>
                  <div class="form-group">
                    <label for="email" class="form-label">{{ 'profile.EMAIL' | translate }}</label>
                    <input type="text"
                           id="email"
                           formControlName="email"
                           [placeholder]="'admin.TYPE_IN' | translate"
                           data-lpignore="true"
                           class="form-control form-control__input" />
                  </div>
                </div>
                <div class="admin-content__actions justify-start">
                  <button class="confirm-btn"
                          (click)="saveAccountData()"
                          [class.inactive]="accountFormData.invalid"
                          [class.erom-spinner]="submitting"
                          [disabled]="accountFormData.invalid || submitting">
                    {{ 'admin.SAVE_CHANGES' | translate }}
                  </button>
                  <button class="discard-btn" (click)="discardChanges()">
                    {{ 'admin.DISCARD_CHANGES' | translate }}
                  </button>
                </div>
              </form>
            </ng-template>
          </div>
        </div>
        <div class="admin-content__section no-paddings" [class.inactive]="activeSection !== 'password'">
          <div class="admin-content__section--header" [class.inactive]="activeSection !== 'password'">
            <h2>{{ 'profile.PASSWORD' | translate }}</h2>
            <button *ngIf="activeSection !== 'password'" (click)="activateSection('password')">
              <fa-icon [icon]="['fas', 'pen']"></fa-icon>
              <span>{{ 'profile.UPDATE' | translate }}</span>
            </button>
          </div>
          <div class="admin-content__section--content">
            <div class="admin-content__section--text">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
              dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
            </div>
            <ng-container *ngIf="activeSection === 'password'">
              <form autocomplete="off" [formGroup]="passwordFormData" (ngSubmit)="saveAccountPassword()">
                <div class="user-profile__data-form password-form">
                  <div class="form-group" *ngIf="!isRoot || isOwnProfile">
                    <label for="oldPassword" class="form-label">{{ 'profile.OLD_PASSWORD' | translate }}</label>
                    <input [type]="showPassword ? 'text' : 'password'"
                           id="oldPassword"
                           formControlName="oldPassword"
                           autocomplete="new-password"
                           [placeholder]="'admin.TYPE_IN' | translate"
                           data-lpignore="true"
                           class="form-control form-control__input" />
                    <button class="form-control form-control__show-password"
                            type="button"
                            [class.active]="showPassword"
                            [attr.aria-label]="'profile.SHOW_PASSWORD' | translate"
                            (click)="showPassword = !showPassword">
                      <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                    </button>
                  </div>
                  <div class="form-group">
                    <label for="password" class="form-label">{{ 'profile.NEW_PASSWORD' | translate }}</label>
                    <input [type]="showNewPassword ? 'text' : 'password'"
                           id="password"
                           formControlName="password"
                           autocomplete="new-password"
                           [placeholder]="'admin.TYPE_IN' | translate"
                           data-lpignore="true"
                           class="form-control form-control__input" />
                    <button class="form-control form-control__show-password"
                            type="button"
                            [class.active]="showNewPassword"
                            [attr.aria-label]="'profile.SHOW_PASSWORD' | translate"
                            (click)="showNewPassword = !showNewPassword">
                      <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                    </button>
                  </div>
                  <div class="form-group">
                    <label for="retypePassword" class="form-label">{{ 'profile.RETYPE_PASSWORD' | translate }}</label>
                    <input [type]="showRetypePassword ? 'text' : 'password'"
                           id="retypePassword"
                           formControlName="retypePassword"
                           autocomplete="new-password"
                           [placeholder]="'admin.TYPE_IN' | translate"
                           data-lpignore="true"
                           class="form-control form-control__input" />
                    <button class="form-control form-control__show-password"
                            type="button"
                            [class.active]="showRetypePassword"
                            [attr.aria-label]="'profile.SHOW_PASSWORD' | translate"
                            (click)="showRetypePassword = !showRetypePassword">
                      <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                    </button>
                  </div>
                </div>
                <div class="admin-content__actions justify-start">
                  <button class="confirm-btn"
                          (click)="saveAccountPassword()"
                          [class.inactive]="passwordFormData.invalid"
                          [class.erom-spinner]="submitting"
                          [disabled]="passwordFormData.invalid || submitting">
                    {{ 'admin.SAVE_CHANGES' | translate }}
                  </button>
                  <button class="discard-btn" (click)="discardChanges()">
                    {{ 'admin.DISCARD_CHANGES' | translate }}
                  </button>
                </div>
              </form>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
