import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[routerLinkInHtml]'
})
export class RouterLinkInHtmlDirective {

  constructor(private router: Router) { }

  @HostListener('click', ['$event'])
  public onClick(event) {

    if (event.target.tagName === 'A' || event.target.parentElement.tagName === 'A') {

      const element = event.target.tagName === 'A' ? event.target : event.target.parentElement;

      if (element.href.toLowerCase().startsWith('mailto:') || element.href.toLowerCase().startsWith('tel:')) {
        return;
      }

      if (location.hostname === element.hostname || !element.hostname.length) {

        event.preventDefault();

        const url = element.getAttribute('href');

        const queryObject = RouterLinkInHtmlDirective.getAllUrlParams(url);

        const cleanUrl = element.getAttribute('href').split('?')[0];

        if (cleanUrl.startsWith('#')) {
          this.router.navigate([], { queryParams: queryObject, fragment: cleanUrl.replace('#', '') });
        } else {
          this.router.navigate([cleanUrl], { queryParams: queryObject });
        }

        return;

      } else {
        return;
      }

    } else {
      return;
    }
  }

  private static getAllUrlParams (url) {

    let queryString = url.split('?')[1];

    const obj = {};

    if (queryString) {
      queryString = queryString.split('#')[0];
      const arr = queryString.split('&');
      for (let i = 0; i < arr.length; i++) {
        const a = arr[i].split('=');
        let paramName = a[0];
        let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') {
          paramValue = paramValue.toLowerCase();
        }
        if (paramName.match(/\[(\d+)?\]$/)) {
          const key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) {
            obj[key] = [];
          }
          if (paramName.match(/\[\d+\]$/)) {
            const index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            obj[key].push(paramValue);
          }
        } else {
          if (!obj[paramName]) {
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;

  }

}
