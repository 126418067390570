import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message, MessageType } from './message.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private subject: Subject<Message>;

  readonly defaultId: string;

  constructor() {
    this.subject = new Subject<Message>();
    this.defaultId = 'eromMessageInformer';
  }

  public onMessage(id: string = this.defaultId): Observable<Message> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  public success(message: string, options?: any): void {
    this.message(new Message({ ...options, type: MessageType.Success, message }));
  }

  public error(message: string, options?: any): void {
    this.message(new Message({ ...options, type: MessageType.Error, message }));
  }

  public info(message: string, options?: any): void {
    this.message(new Message({ ...options, type: MessageType.Info, message }));
  }

  public warn(message: string, options?: any): void {
    this.message(new Message({ ...options, type: MessageType.Warning, message }));
  }

  public message(message: Message): any {
    message.id = message.id || this.defaultId;
    this.subject.next(message);
  }

  public clear(id = this.defaultId): void {
    this.subject.next(new Message({ id }));
  }
}
