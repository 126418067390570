import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class API {

  readonly token: string;
  readonly headersAuthSettings: object;
  readonly URLS;

  readonly _clearAssuredDomain: string;

  constructor() {
    this.token = localStorage.getItem('token');
    this.headersAuthSettings = {
      headers: this.token ? { Authorization: `Bearer ${this.token}` } : ''
    };
    this.URLS = {
      userLogin: '/api/v1/auth/accessToken',
      refreshToken: '/api/v1/auth/refreshAccessToken',
      checkActivateToken: '/api/v1/user/activate-token-check',
      checkResetToken: '/api/v1/user/reset-token-check',
      activateAccount: '/api/v1/user/activate',
      resetPassword: '/api/v1/user/reset-password',
      passwordRecover: '/api/v1/user/forgot-password',
      getUser: '/api/v1/auth/accessToken',
      user: '/api/v1/user',
      role: '/api/v1/role',
      rule: '/api/v1/rule',
      file: '/api/v1/file',
      plan: '/api/v1/plan',
      permission: '/api/v1/permission',
      invoice: '/api/v1/invoice',
      marketplace: '/api/v1/marketplace',
      voucher: '/api/v1/voucher',
      organizationType: '/api/v1/organization-type',
      billing: '/api/v1/billing'
    };

    if (location.hostname === 'billingdev.clearassured.co.uk') {
      this._clearAssuredDomain = 'https://stage.clearassured.co.uk';
    } else if (location.hostname === 'billing.clearassured.co.uk') {
      this._clearAssuredDomain = 'https://clearassured.co.uk';
    } else {
      this._clearAssuredDomain = 'http://localhost:8080';
    }
  }

  public get clearAssuredDomain(): string {
    return this._clearAssuredDomain;
  }

}
