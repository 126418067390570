import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '../../../../src/app/common/page-not-found/page-not-found.component';
import { InvalidTokenComponent } from '../../../../src/app/common/invalid-token/invalid-token.component';
import { AppComponent } from './app.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'stripe-payment/:companyId',
        component: StripePaymentComponent
      }
    ]
  },
  {
    path: '404',
    data: {
      breadcrumb: 'navigation.NOT_FOUND_PAGE'
    },
    component: PageNotFoundComponent
  },
  {
    path: '498',
    data: {
      breadcrumb: 'navigation.NOT_FOUND_PAGE'
    },
    component: InvalidTokenComponent
  },
  {
    path: '**',
    data: {
      breadcrumb: 'navigation.NOT_FOUND_PAGE'
    },
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
