import { Component, OnInit, ViewChild } from '@angular/core';

import { StripeService, StripeCardNumberComponent } from 'ngx-stripe';
import { PaymentIntent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { API } from '../../../../../src/app/_store';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit {

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  private _companyId: string;
  private _renew: boolean;
  private _invoice: string;
  private _voucher: string;
  private _checkHash: string;
  private _invalidCheckHash: boolean;

  public cardOptions: StripeCardElementOptions;
  public elementsOptions: StripeElementsOptions;
  public stripeForm: UntypedFormGroup;
  public paymentStarted: boolean;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private api: API,
    private fb: UntypedFormBuilder,
    private stripeService: StripeService
  ) {
    this.cardOptions = {
      style: {
        base: {
          iconColor: '#595959',
          color: '#272727',
          fontWeight: '400',
          fontFamily: '"Open Sans", Helvetica, sans-serif',
          lineHeight: '22px',
          fontSize: '15px',
          '::placeholder': {
            color: '#7d7d7d'
          }
        }
      }
    };
    this.elementsOptions = {
      locale: 'en-GB',
      appearance: {
        theme: 'stripe',
        variables: {
          borderRadius: '4px',
          colorDanger: '#000000',
          colorDangerText: '#000000'
        }
      }
    };
  }

  public ngOnInit(): void {
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.route.params.subscribe(params => {
      this._companyId = params.companyId;
    });
    this.route.queryParams.subscribe(params => {
      this._voucher = params.voucher;
      this._checkHash = params.check;
      this._renew = params.renew || false;
      this._invoice = params.invoice;
      this._invalidCheckHash = this._checkHash !== '0dce2bbbc190a8efb559d6dc892f677c';
    });
  }

  public pay(): void {
    this.paymentStarted = true;
    window.parent.postMessage('payment_process_started', this.api.clearAssuredDomain);
    this.createPaymentIntent({
      companyId: this._companyId,
      voucher: this._voucher,
      invoiceId: this._invoice
    }).pipe(switchMap((pi) =>
        this.stripeService.confirmCardPayment(pi.client_secret, {
          payment_method: {
            card: this.card.element
          }
        })
      )
    ).subscribe((result) => {
      if (result.error) {
        window.parent.postMessage(`payment_process_error::${ result.error.message }`, this.api.clearAssuredDomain);
        this.paymentStarted = false;
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          // this.paymentSucceed({
          //   paymentIntentId: result.paymentIntent.id
          // }).subscribe(response => {
          //   console.log(response);
          // }, error => {
          //   console.log(error);
          // });
          window.parent.postMessage('payment_process_succeeded', this.api.clearAssuredDomain);
          this.paymentStarted = false;
        }
      }
    });
  }

  private createPaymentIntent(data: object): Observable<PaymentIntent> {
    if (this._renew) {
      return this.http.post<PaymentIntent>(`${this.api.URLS.billing}/stripe/create-renew-payment-intent`, data);
    } else {
      return this.http.post<PaymentIntent>(`${this.api.URLS.billing}/stripe/create-payment-intent`, data);
    }
  }

  // private paymentSucceed(data: object): Observable<any> {
  //   return this.http.post(`${this.api.URLS.billing}/customer/confirm-payment`, data);
  // }

  public get invalidCheckHash(): boolean {
    return this._invalidCheckHash;
  }

}
