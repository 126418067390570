<div class="erom-select"
     [id]="selectId"
     [ngClass]="dropdownClasses">

  <ng-container *ngIf="isMobile; else desktop">
    <div class="erom-select-native">
      <label [for]="selectId + '-native-select'" *ngIf="label">{{ labelText }}</label>
      <select [id]="selectId + '-native-select'"
              *ngIf="options"
              [attr.multiple]="multiple || null" (change)="onNativeSelect($event)">
        <option *ngFor="let option of options"
                [value]="option.value"
                [attr.selected]="isOptionSelected(option) ? 'selected' : null">
          {{ getOptionLabel(option) }}
        </option>
      </select>
    </div>
  </ng-container>

  <ng-template #desktop>
    <span [id]="selectId + '-placeholder'" aria-hidden="true" hidden>
      {{ label || ariaLabel }}
      <ng-container *ngIf="showLabelCounter && countSelected && countSelected > 0">
        ({{ countSelected }})
      </ng-container>
    </span>

    <div class="erom-select-face"
         [ngClass]="type ? 'type_' + type : ''">
      <div class="erom-select-placeholder"
           [class.has-label]="label && type === 'gray'">
        <span class="text-placeholder"
              aria-hidden="true"
              [id]="selectId + '-placeholder-value'"
              [hidden]="isOpened">
          <ng-container *ngIf="!multiple && !allowNewTags && selectedLabel">
            {{ selectedLabel }}
          </ng-container>
        </span>
        <label [for]="selectId + '-search'" *ngIf="label" aria-hidden="true">{{ labelText }}</label>
        <input #searchInput
               type="text"
               role="combobox"
               aria-haspopup="listbox"
               [attr.aria-expanded]="isOpened || false"
               [attr.aria-autocomplete]="!searchable || !isOpened ? null : 'list'"
               [attr.aria-controls]="selectId + '-listbox'"
               [id]="selectId + '-search'"
               (input)="onInput()"
               (keydown.arrowUp)="typeAheadUp($event)"
               (keydown.arrowDown)="typeAheadDown($event)"
               (keydown.arrowRight)="onRight($event)"
               (keydown.home)="typeAheadHome($event)"
               (keydown.end)="typeAheadEnd($event)"
               (keydown.enter)="onEnter($event, true)"
               (keydown.esc)="onEscape($event)"
               (keydown.space)="onSpace($event)"
               (focus)="onSearchFocus($event)"
               (mousedown)="toggleSelect($event)"
               (blur)="onSearchBlur($event)"
               [attr.value]="allowNewTags && value ? value : ''"
               [attr.aria-activedescendant]="descendentId"
               [attr.aria-labelledby]="multiple ? selectId + '-placeholder' : (!!selected ? (selectId + '-placeholder ' + selectId + '-placeholder-value') : selectId + '-placeholder')"
               [attr.aria-describedby]="ariaDescribedBy || null"
               [placeholder]="placeholderText"
               [attr.readonly]="!searchable ? true : null" />
      </div>
      <ng-container *ngIf="!allowNewTags">
        <button class="erom-select-arrow"
                tabindex="-1"
                aria-haspopup="listbox"
                [attr.aria-controls]="selectId + '-listbox'"
                [attr.aria-expanded]="isOpened || false"
                [attr.aria-hidden]="!isOpened"
                [attr.aria-labelledby]="multiple ? selectId + '-placeholder' : (!!selected ? (selectId + '-placeholder ' + selectId + '-placeholder-value') : selectId + '-placeholder')"
                (click)="toggleSelect($event, true)">
          <svg xmlns="http://www.w3.org/2000/svg" width="8.75" height="5.25" viewBox="0 0 8.75 5.25">
            <path fill="currentColor" d="M8.559-6.809a.633.633,0,0,1,.191.465.547.547,0,0,1-.191.438L4.84-1.969a.547.547,0,0,1-.465.219.547.547,0,0,1-.465-.219L.191-5.906A.547.547,0,0,1,0-6.344a.633.633,0,0,1,.191-.465A.633.633,0,0,1,.656-7H8.094A.633.633,0,0,1,8.559-6.809Z" transform="translate(0 7)"/>
          </svg>
        </button>
      </ng-container>
      <ng-container *ngIf="allowNewTags">
        <svg class="erom-select-arrow search-icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
        </svg>
      </ng-container>
    </div>

    <div class="erom-select-list_wrap_overflow">
      <ul #dropdownMenuList
          tabindex="-1"
          class="erom-select-list_wrap"
          role="listbox"
          [attr.aria-hidden]="!isOpened"
          [attr.aria-expanded]="isOpened"
          [ngClass]="type ? 'type_' + type : ''"
          [id]="selectId + '-listbox'"
          [attr.aria-labelledby]="selectId ? selectId + '-placeholder' : 'select-placeholder'">
        <li class="erom-select-list_item"
            role="option"
            *ngFor="let option of filteredOptions; let i = index"
            (mousedown)="onSelect($event, option)"
            [attr.aria-selected]="!checkbox ? isOptionSelected(option) : null"
            [id]="selectId + '-' + 'item-' + i"
            [class.active]="isOptionSelected(option)"
            [class.focused]="isOptionHighLighted(option, i)"
            [attr.aria-labelledby]="selectId + '-' + 'item-label' + i"
            [attr.data-value]="option.value"
            [attr.aria-checked]="checkbox ? isOptionSelected(option) : null"
            [class.checkbox-type]="checkbox">
          <span class="erom-visually-hidden" [id]="selectId + '-' + 'item-label' + i">{{ getOptionLabel(option) }}</span>
          <ng-container *ngIf="checkbox">
            <div class="erom-select-list_item-checkbox">
              <input [id]="selectId + '-' + 'item-input-' + i"
                     tabindex="-1"
                     aria-hidden="true"
                     [checked]="isOptionSelected(option)"
                     type="checkbox"
                     [name]="getOptionLabel(option)"
                     [attr.data-value]="option.value" />
              <label [for]="selectId + '-' + 'item-input-' + i"
                     (click)="$event.preventDefault()">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
                {{ getOptionLabel(option) }}
              </label>
            </div>
          </ng-container>
          <span *ngIf="!checkbox">
          {{ getOptionLabel(option) }}
        </span>
        </li>
        <li class="erom-select-list_item empty" *ngIf="!hasItems && !searchLoading">
          {{ noItemsFoundText ? noItemsFoundText : 'select.NO_ITEMS' | translate }}
        </li>
      </ul>
    </div>
    <div class="erom-select-live" aria-live="polite">
      <ng-container *ngIf="availableResultsLive">
        <ng-container *ngIf="filteredOptions.length === 1">
          {{ 'select.AVAILABLE_RESULTS_LIVE' | translate : { type: (label ? label : placeholderText).toLowerCase() } }}
        </ng-container>
        <ng-container *ngIf="filteredOptions.length !== 1">
          {{ 'select.AVAILABLE_RESULTS_LIVE_PLURAL' | translate : { count: filteredOptions.length, type: (label ? label : placeholderText).toLowerCase() } }}
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="multiple && tagsFormat && selected && selected.length">
  <div class="erom-select-multiple-selected">
    <button type="button"
            (click)="onSelect($event, select)"
            [tabIndex]="isOpened ? -1 : 0"
            [attr.aria-hidden]="isOpened"
            [attr.aria-label]="('select.REMOVE' | translate) + ' ' + (label ? label : placeholderText).toLowerCase() + ': '  + select['label']"
            *ngFor="let select of selected">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <path fill="currentColor" d="M16.172-3.422a1.085,1.085,0,0,1,.328.8,1.085,1.085,0,0,1-.328.8,1.085,1.085,0,0,1-.8.328,1.085,1.085,0,0,1-.8-.328L9-7.406,3.422-1.828a1.085,1.085,0,0,1-.8.328,1.085,1.085,0,0,1-.8-.328,1.085,1.085,0,0,1-.328-.8,1.085,1.085,0,0,1,.328-.8L7.406-9,1.828-14.578a1.085,1.085,0,0,1-.328-.8,1.085,1.085,0,0,1,.328-.8,1.085,1.085,0,0,1,.8-.328,1.085,1.085,0,0,1,.8.328L9-10.594l5.578-5.578a1.085,1.085,0,0,1,.8-.328,1.085,1.085,0,0,1,.8.328,1.085,1.085,0,0,1,.328.8,1.085,1.085,0,0,1-.328.8L10.594-9Z" transform="translate(-1.5 16.5)"/>
      </svg>
      {{ select.label }}
    </button>
  </div>
</ng-container>
