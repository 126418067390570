import { UntypedFormGroup, Validators } from '@angular/forms';

export const PasswordValidation = [
  Validators.required,
  Validators.minLength(6),
  Validators.maxLength(24),
];

export function RepeatPasswordValidator(group: UntypedFormGroup): object {
  const password = group.controls.password.value;
  const passwordConfirmation = group.controls.retypePassword.value;
  return password === passwordConfirmation ? null : { passwordsNotEqual: true };
}
