<form class="stripe-payment-form"
      novalidate
      ngxStripeCardGroup
      (ngSubmit)="pay()"
      [elementsOptions]="elementsOptions"
      [formGroup]="stripeForm">
  <ng-container *ngIf="invalidCheckHash; else validIframeIntegration">
    Invalid iframe integration
  </ng-container>
  <ng-template #validIframeIntegration>
    <div class="stripe-payment-form--loading" *ngIf="paymentStarted">
      <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="spinner-third" class="svg-inline--fa fa-spinner-third" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g class="fa-group"><path class="fa-secondary" fill="#ffffff" d="M493.7 350.5C456 444.9 363.7 512 256 512c-141.2 0-256-114.8-256-256s114.8-256 256-256c11.91 0 23.54 1.104 35.03 2.686C272.4 .8672 256 15.62 256 34.52c0 15.8 11.58 29.17 27.23 31.4c.9648 .1367 1.844 .5116 2.805 .6639C276.2 65.04 266.2 64 256 64C150.1 64 64 150.1 64 256s86.13 192 192 192c81.67 0 151.4-51.34 179.1-123.4c-.248 .6406-.4215 1.317-.6754 1.954c-5.869 14.7 .0045 31.38 13.75 39.23C464.8 375.3 486.5 368.2 493.7 350.5z"></path><path class="fa-primary" fill="#22cd97" d="M283.2 65.92C267.6 63.69 256 50.32 256 34.52c0-19.38 17.19-34.69 36.38-31.95C416.4 20.29 512 127.2 512 256c0 33.38-6.544 65.26-18.27 94.49c-7.162 17.86-28.85 24.87-45.56 15.32c-13.74-7.854-19.62-24.53-13.75-39.23C443.2 304.7 448 280.9 448 256C448 159.4 376.3 79.18 283.2 65.92z"></path></g></svg>
    </div>
    <div class="stripe-payment-form--container" [class.hidden]="paymentStarted">
      <div class="stripe-payment-form--group">
        <div class="stripe-payment-form--label">
          Credit card number
          <span class="stripe-payment-form--label__info">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-info" class="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"></path></svg>
          <span class="info-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="108" height="70.996" viewBox="0 0 108 70.996">
              <g id="CCfront-CardNumber" transform="translate(-1243 -552.504)">
                <path id="Subtraction_17" data-name="Subtraction 17" d="M-12128-8244h-70a10.011,10.011,0,0,1-10-10v-50a9.935,9.935,0,0,1,2.929-7.071A9.939,9.939,0,0,1-12198-8314h70a10.013,10.013,0,0,1,10,10v50A10.011,10.011,0,0,1-12128-8244Zm-16.928-19-2.071,7.73.966.26,2.071-7.732-.966-.258Zm12.967,2a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12131.962-8261Zm-6,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12137.961-8261Zm-15.037,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12153-8261Zm-6,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12159-8261Zm-39,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2h20a2,2,0,0,0,2-2,2,2,0,0,0-2-2Zm60-18a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm52.5-25a2.5,2.5,0,0,0-2.5,2.5v10a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5v-10a2.5,2.5,0,0,0-2.5-2.5Z" transform="translate(13460 8867.004)" fill="#a9ebd6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Intersection_6" data-name="Intersection 6" d="M-12201-8270.73v-12.034c9-3.577,25.782-5.983,45-5.983s36,2.406,45,5.984v12.032c-9,3.577-25.785,5.982-45,5.982S-12192-8267.153-12201-8270.73Zm68-6.271v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2h-8A2,2,0,0,0-12133-8277Zm-20,0v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2h-8A2,2,0,0,0-12153-8277Zm-20,0v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2h-8A2,2,0,0,0-12173-8277Zm-20,0v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2h-8A2,2,0,0,0-12193-8277Z" transform="translate(13453 8867.004)" fill="#4ed7ab" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <g id="focus_highlight" data-name="focus highlight" transform="translate(1245 578.257)" fill="none" stroke="#595959" stroke-width="2">
                  <ellipse cx="52" cy="12" rx="52" ry="12" stroke="none"/>
                  <ellipse cx="52" cy="12" rx="53" ry="13" fill="none"/>
                </g>
              </g>
            </svg>
          </span>
        </span>
        </div>
        <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
      </div>
      <div class="stripe-payment-form--group">
        <div class="stripe-payment-form--label">
          Expiration date
          <span class="stripe-payment-form--label__info">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-info" class="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"></path></svg>
          <span class="info-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="91.003" height="77.493" viewBox="0 0 91.003 77.493">
              <g id="CCfront-ExpiryDate" transform="translate(-1251.5 -631.504)">
                <path id="Subtraction_21" data-name="Subtraction 21" d="M-12128-8244h-70a10.011,10.011,0,0,1-10-10v-50a9.935,9.935,0,0,1,2.929-7.071A9.939,9.939,0,0,1-12198-8314h70a10.013,10.013,0,0,1,10,10v50A10.011,10.011,0,0,1-12128-8244Zm-16.928-19-2.071,7.73.966.26,2.071-7.732-.966-.258Zm12.967,2a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12131.962-8261Zm-6,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12137.961-8261Zm-15.037,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12153-8261Zm-6,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12159-8261Zm-39,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2h20a2,2,0,0,0,2-2,2,2,0,0,0-2-2Zm60-18a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm52.5-25a2.5,2.5,0,0,0-2.5,2.5v10a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5v-10a2.5,2.5,0,0,0-2.5-2.5Z" transform="translate(13460 8946.004)" fill="#a9ebd6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Intersection_7" data-name="Intersection 7" d="M-12160.6-8244c-4.562-3.276-7.4-7.891-7.4-13,0-5.751,3.6-10.871,9.191-14.167a1.983,1.983,0,0,0,.809.171h8a2,2,0,0,0,2-2v-1.755a31.937,31.937,0,0,1,4-.249,31.984,31.984,0,0,1,4,.249V-8273a2,2,0,0,0,2,2h8a1.99,1.99,0,0,0,.807-.17c5.595,3.295,9.19,8.416,9.19,14.166,0,5.1-2.83,9.708-7.377,12.983q-.309.019-.623.02Zm13.6-11.269.966.26,2.071-7.732-.966-.258Zm13.037-3.729a2,2,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2,2,0,0,0-12133.963-8259Zm-6,0a2,2,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2,2,0,0,0-12139.962-8259Zm-15.04,0a2.006,2.006,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2.005,2.005,0,0,0-12155-8259Zm-6,0a2,2,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2,2,0,0,0-12161-8259Z" transform="translate(13460 8946.004)" fill="#4ed7ab" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <g id="focus_highlight" data-name="focus highlight" transform="translate(1292 670.997)" fill="none" stroke="#595959" stroke-width="2">
                  <ellipse cx="24" cy="18" rx="24" ry="18" stroke="none"/>
                  <ellipse cx="24" cy="18" rx="25" ry="19" fill="none"/>
                </g>
              </g>
            </svg>
          </span>
        </span>
        </div>
        <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
      </div>
      <div class="stripe-payment-form--group">
        <div class="stripe-payment-form--label">
          CVC code
          <span class="stripe-payment-form--label__info">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-info" class="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"></path></svg>
          <span class="info-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="91.003" height="77.493" viewBox="0 0 91.003 77.493">
              <g id="CCfront-ExpiryDate" transform="translate(-1251.5 -631.504)">
                <path id="Subtraction_21" data-name="Subtraction 21" d="M-12128-8244h-70a10.011,10.011,0,0,1-10-10v-50a9.935,9.935,0,0,1,2.929-7.071A9.939,9.939,0,0,1-12198-8314h70a10.013,10.013,0,0,1,10,10v50A10.011,10.011,0,0,1-12128-8244Zm-16.928-19-2.071,7.73.966.26,2.071-7.732-.966-.258Zm12.967,2a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12131.962-8261Zm-6,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12137.961-8261Zm-15.037,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12153-8261Zm-6,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-12159-8261Zm-39,0a2,2,0,0,0-2,2,2,2,0,0,0,2,2h20a2,2,0,0,0,2-2,2,2,0,0,0-2-2Zm60-18a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm-20,0a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2v-4a2,2,0,0,0-2-2Zm52.5-25a2.5,2.5,0,0,0-2.5,2.5v10a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5v-10a2.5,2.5,0,0,0-2.5-2.5Z" transform="translate(13460 8946.004)" fill="#a9ebd6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Intersection_7" data-name="Intersection 7" d="M-12160.6-8244c-4.562-3.276-7.4-7.891-7.4-13,0-5.751,3.6-10.871,9.191-14.167a1.983,1.983,0,0,0,.809.171h8a2,2,0,0,0,2-2v-1.755a31.937,31.937,0,0,1,4-.249,31.984,31.984,0,0,1,4,.249V-8273a2,2,0,0,0,2,2h8a1.99,1.99,0,0,0,.807-.17c5.595,3.295,9.19,8.416,9.19,14.166,0,5.1-2.83,9.708-7.377,12.983q-.309.019-.623.02Zm13.6-11.269.966.26,2.071-7.732-.966-.258Zm13.037-3.729a2,2,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2,2,0,0,0-12133.963-8259Zm-6,0a2,2,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2,2,0,0,0-12139.962-8259Zm-15.04,0a2.006,2.006,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2.005,2.005,0,0,0-12155-8259Zm-6,0a2,2,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2,2,0,0,0-12161-8259Z" transform="translate(13460 8946.004)" fill="#4ed7ab" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                <g id="focus_highlight" data-name="focus highlight" transform="translate(1292 670.997)" fill="none" stroke="#595959" stroke-width="2">
                  <ellipse cx="24" cy="18" rx="24" ry="18" stroke="none"/>
                  <ellipse cx="24" cy="18" rx="25" ry="19" fill="none"/>
                </g>
              </g>
            </svg>
          </span>
        </span>
        </div>
        <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
      </div>
      <button type="submit" class="stripe-payment-form--button">
        Pay now
      </button>
    </div>
  </ng-template>
</form>
