import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class BillingGlobals {

  readonly _sizeUnits: Array<object>;
  readonly _paymentUnits: Array<object>;
  readonly _commitments: Array<object>;

  constructor(
    private translateService: TranslateService
  ) {
    this._sizeUnits = [
      {
        value: 1,
        min: 1,
        max: 9,
        label: 'Micro (1-9)'
      },
      {
        value: 2,
        min: 10,
        max: 49,
        label: 'Small (10-49)'
      },
      {
        value: 3,
        min: 50,
        max: 249,
        label: 'Medium (50-249)'
      },
      {
        value: 4,
        min: 250,
        max: 999,
        label: 'Large (250-999)'
      },
      {
        value: 5,
        min: 1000,
        max: 1000000,
        label: 'Enterprise (1000+)'
      }
    ];
    this._paymentUnits = [
      {
        value: 1,
        label: 'Month'
      },
      {
        value: 2,
        label: 'Year'
      }
    ];
    this._commitments = [
      {
        value: 1,
        label: '1 Year'
      },
      {
        value: 2,
        label: '3 Years'
      }
    ];
  }

  get sizeUnits(): Array<object> {
    return this._sizeUnits;
  }

  get paymentUnits(): Array<object> {
    return this._paymentUnits;
  }

  get commitments(): Array<object> {
    return this._commitments;
  }
}
