import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { API, BillingGlobals, EromIoGlobals } from './_store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  faWindowRestore,
  faFile,
  faPen,
  faCube,
  faEllipsisV,
  faExternalLinkAlt,
  faTachometerAlt,
  faChevronDown,
  faUserFriends,
  faStream,
  faPlus,
  faMinus,
  faTag,
  faUserTag,
  faClipboardList,
  faUserCircle,
  faAngleDoubleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faCog,
  faWrench,
  faGlobe,
  faFlag,
  faKey,
  faBell,
  faUser,
  faBars,
  faTimes,
  faSitemap,
  faTags,
  faPencilRuler,
  faPaintRoller,
  faAtom,
  faDna,
  faClipboard,
  faArrowCircleLeft,
  faEdit,
  faEye,
  faShare,
  faSearch,
  faChevronUp,
  faFilter,
  faCheck,
  faCloudDownloadAlt,
  faTrash,
  faFolder,
  faSlidersH,
  faInfoCircle,
  faClone,
  faTimesCircle,
  faCheckCircle,
  faExclamationCircle,
  faFileDownload,
  faUpload,
  faRedo,
  faMinusCircle,
  faPlusCircle,
  faArrowCircleUp,
  faMapSigns,
  faSyncAlt,
  faEyeSlash,
  faShapes, faSave, faFileImage, faFileExport, faFileImport,
  faFileInvoiceDollar, faWallet, faCreditCard, faBoxes, faChartBar, faPercentage, faLandmark
} from '@fortawesome/free-solid-svg-icons';

import { BreadcrumbsComponent } from './_includes/breadcrumbs/breadcrumbs.component';
import { AuthenticationService } from './_services/authentication.service';
import { BreadcrumbsService } from './_services/breadcrumbs.service';
import { LoginComponent } from './common/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './_guards';
import { ProfileComponent } from './common/profile/profile.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ErrorInterceptor, JwtInterceptor } from './_interceptors';
import { TranslateHelper } from './_helpers';
import { EromTableModule } from './_directives/erom-table/erom-table.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EromSelectModule } from './_directives/erom-select/erom-select.module';
import { InViewportModule } from 'ng-in-viewport';
import { RouterLinkInHtmlDirective } from './_directives/router-link-in-html/router-link-in-html.directive';
import { EromBulkPasteModule } from './_directives/erom-bulk-paste/erom-bulk-paste.module';
import { EromMessageInformerModule } from './_directives/erom-message-informer/erom-message-informer.module';
import { ActivateAccountComponent } from './common/activate-account/activate-account.component';
import { InvalidTokenComponent } from './common/invalid-token/invalid-token.component';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './_services/lower-case-url-serializer.service';
import {NgScrollbarModule} from "ngx-scrollbar";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/');
}

const declarations = [
  LoginComponent,
  ProfileComponent,
  PageNotFoundComponent,
  BreadcrumbsComponent,
  RouterLinkInHtmlDirective,
  ActivateAccountComponent,
  InvalidTokenComponent,
  ResetPasswordComponent,
  ForgotPasswordComponent
];

const providers = [
  AuthenticationService,
  AuthGuard,
  EromIoGlobals,
  BillingGlobals,
  API,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },
  {
    provide: UrlSerializer,
    useClass: LowerCaseUrlSerializer
  },
  TranslateHelper,
  BreadcrumbsService
];

@NgModule({ declarations,
    bootstrap: [],
    exports: [
        ...declarations,
        TranslateModule,
        NgScrollbarModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        EromTableModule,
        EromSelectModule,
        EromBulkPasteModule,
        EromMessageInformerModule
    ], imports: [BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgScrollbarModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        EromTableModule,
        EromSelectModule,
        InViewportModule,
        EromBulkPasteModule,
        EromMessageInformerModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(faWindowRestore, faFile, faPen, faCube, faEllipsisV, faExternalLinkAlt, faTachometerAlt, faChevronDown,
      faUserFriends, faStream, faPlus, faMinus, faUserTag, faTag, faClipboardList, faUserCircle, faAngleDoubleLeft, faMapMarkerAlt,
      faEnvelope, faCog, faWrench, faGlobe, faFlag, faKey, faBell, faUser, faBars, faTimes, faSitemap, faTags, faPencilRuler,
      faPaintRoller, faAtom, faDna, faClipboard, faArrowCircleLeft, faEdit, faEye, faShare, faSearch, faChevronUp, faFilter, faCheck,
      faCloudDownloadAlt, faTrash, faFolder, faSlidersH, faInfoCircle, faClone, faTimesCircle, faCheckCircle, faExclamationCircle,
      faFileDownload, faUpload, faRedo, faMinusCircle, faArrowCircleUp, faPlusCircle, faMapSigns, faSyncAlt, faEyeSlash, faShapes, faSave,
      faFileImage, faFileExport, faFileImport, faCreditCard, faBoxes, faFileInvoiceDollar, faChartBar, faPercentage, faLandmark);
  }
}

export class SharedModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers
    };
  }
}
