<div class="login-page">
  <div class="login-page-wrapper" [class.info-message]="submitting || submitted">
    <div class="login-page-header">
      <a routerLink="/" [attr.aria-label]="'front.SITE_NAME' | translate" class="login-page-header__logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="47.273" height="10" viewBox="0 0 47.273 10" fill="currentColor">
          <path class="a" d="M225.529,117.873c0,.246-.02.474-.039.7a.464.464,0,0,1-.52.435h-6.434a2.182,2.182,0,0,0,2.254,
                1.572,2.329,2.329,0,0,0,1.425-.474.9.9,0,0,1,.6-.246l1.85-.019c.347,0,.54.227.386.53a4.426,4.426,0,0,1-4.3,2.5,
                5,5,0,1,1-.077-10A4.79,4.79,0,0,1,225.529,117.873Zm-2.877-1.023a1.925,1.925,0,0,0-2-1.458,2.045,2.045,0,0,0-2.13,1.458Z"
                transform="translate(-215.509 -112.873)"/>
          <path class="a" d="M230.53,113.611a.433.433,0,0,0-.482-.473h-1.927a.433.433,0,
                0,0-.481.473v8.523a.433.433,0,0,0,.481.473h1.927a.433.433,0,0,0,.482-.473Z" transform="translate(-215.693 -112.873)"/>
          <path class="a" d="M237.749,117.873a5.107,5.107,0,1,1,5.106,5A5.007,5.007,0,0,1,237.749,117.873Zm7.322,0a2.216,2.216,0,1,
                0-2.215,2.235A2.141,2.141,0,0,0,245.071,117.873Z" transform="translate(-215.846 -112.873)"/>
          <path class="a" d="M236.376,115.392a2.216,2.216,0,1,0-2.215,2.235A2.142,2.142,0,0,0,236.376,115.392Z"
                transform="translate(-215.758 -112.873)"/>
          <path class="a" d="M260.324,113.005c1.889,0,2.987,1.175,2.987,
                3.352v5.909a.433.433,0,0,1-.482.473H260.9a.433.433,0,0,1-.482-.473V116.6a.886.886,0,0,0-1-.985c-.579,
                0-1.118.284-1.118,1.212v5.436a.432.432,0,0,1-.482.473h-1.927a.432.432,0,0,1-.482-.473V116.6a.886.886,0,
                0,0-1-.985c-.578,0-1.117.284-1.117,1.212v5.436a.433.433,0,0,1-.482.473h-1.927a.432.432,0,0,1-.481-.473v-8.523a.433.433,
                0,0,1,.481-.473h1.927a.433.433,0,0,1,.482.473v.387a2.6,2.6,0,0,1,2.216-1.125,2.532,2.532,0,0,1,2.233,1.127,3.392,
                3.392,0,0,1,2.583-1.127Z" transform="translate(-216.038 -112.873)"/>
        </svg>
      </a>
      <h1 class="login-page-header__title">{{ 'front.FORGOT_PASSWORD' | translate }}</h1>
    </div>
    <div class="login-page-container">
      <ng-container *ngIf="!submitting && !submitted; else submittingArea">
        <form class="login-page-form" [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="email" class="form-label">{{ 'front.USERNAME_EMAIL' | translate }}</label>
            <input id="email"
                   type="email"
                   class="form-control form-control__input"
                   formControlName="email"
                   [class.invalid]="forgotForm.touched && f.email.touched && (f.email.errors)" />
          </div>
          <div class="form-actions">
            <button type="submit"
                    class="form-submit"
                    [class.erom-spinner]="submitting"
                    [disabled]="submitting">{{ 'front.FORGOT_PASSWORD' | translate }}</button>
          </div>
        </form>
      </ng-container>
      <ng-template #submittingArea>
        <ng-container *ngIf="submitting; else formSubmitted">
          <div class="login-page-loading" aria-live="polite">
            <div class="login-page-loading--icon">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M96 256c0-26.5-21.5-48-48-48S0 229.5 0 256s21.5 48 48 48S96 282.5 96 256zM108.9 60.89c-26.5 0-48.01 21.49-48.01 47.99S82.39 156.9 108.9 156.9s47.99-21.51 47.99-48.01S135.4 60.89 108.9 60.89zM108.9 355.1c-26.5 0-48.01 21.51-48.01 48.01S82.39 451.1 108.9 451.1s47.99-21.49 47.99-47.99S135.4 355.1 108.9 355.1zM256 416c-26.5 0-48 21.5-48 48S229.5 512 256 512s48-21.5 48-48S282.5 416 256 416zM464 208C437.5 208 416 229.5 416 256s21.5 48 48 48S512 282.5 512 256S490.5 208 464 208zM403.1 355.1c-26.5 0-47.99 21.51-47.99 48.01S376.6 451.1 403.1 451.1s48.01-21.49 48.01-47.99S429.6 355.1 403.1 355.1zM256 0C229.5 0 208 21.5 208 48S229.5 96 256 96s48-21.5 48-48S282.5 0 256 0z"></path></svg>
            </div>
            <h2 class="login-page-loading--text">{{ 'front.FORGOT_PASSWORD_SUBMITTING' | translate }}</h2>
          </div>
        </ng-container>
        <ng-template #formSubmitted>
          <div class="login-page-loading">
            <div class="login-page-loading--icon">
              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path></svg>
            </div>
            <h2 class="login-page-loading--text">{{ 'front.FORGOT_PASSWORD_SUBMITTED' | translate }}</h2>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
