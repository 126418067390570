import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@Injectable()
@AutoUnsubscribe()
export class TranslateHelper implements OnDestroy {

  public constructor(
    private translateService: TranslateService
  ) { }

  ngOnDestroy(): void {
  }

  public translate(text: string, params: Object = {}): string {
    let translation = text;
    this.translateService.get(text, params).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }
}
