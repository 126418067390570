import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { API } from '../_store';
import { UuidService } from './uuid.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService {

  private _isLoggedIn: boolean;
  private _refreshAccessTokenProcessStarted: boolean;
  private _refreshAccessTokenInterval: any;

  constructor(
    private http: HttpClient,
    private api: API,
    private uuidService: UuidService,
    private router: Router
  ) {
    this._isLoggedIn = !!this.tokens;
    this._refreshAccessTokenProcessStarted = false;
  }

  public get tokens(): any {
    return JSON.parse(localStorage.getItem('token'));
  }

  protected get currentUser(): any {
    const currentUser = this.tokens;
    if (!currentUser) {
      return null;
    }
    const jwtHelper = new JwtHelperService();
    return jwtHelper.decodeToken(currentUser.accessToken);
  }

  public get isLoggedIn(): boolean {
    if (!this._refreshAccessTokenProcessStarted && this._isLoggedIn) {
      this.refreshAccessTokenNow();
      this.startRefreshAccessTokenInterval();
      this._isLoggedIn = !!this.tokens;
    }
    return this._isLoggedIn;
  }

  public get userName(): string {
    const currentUser = this.currentUser;
    if (currentUser && (currentUser.name || currentUser.surname)) {
      return [currentUser.name, currentUser.surname].join(' ');
    } else {
      return 'profile.MY_PROFILE';
    }
  }

  public login(email: string, password: string): Observable<any> {
    const loginData = {
      id: this.uuidService.uuid,
      email,
      password
    };
    return this.http.post<any>(`${ this.api.URLS.userLogin }`, loginData).pipe(map(user => {
      if (user && user.accessToken) {
        this.updateFrontOnLogin(user);
      }
      return user;
    }));
  }

  private updateFrontOnLogin(user): void {
    if (user && user.accessToken) {
      const tokens = {
        accessToken: user.accessToken,
        refreshToken: user.refreshToken
      };
      this.saveInLocalTokenAndUser(tokens);
      this.startRefreshAccessTokenInterval();
    }
  }

  private saveInLocalTokenAndUser(tokens): void {
    localStorage.setItem('token', JSON.stringify(tokens));
    this._isLoggedIn = true;
  }

  private startRefreshAccessTokenInterval(): void {
    this._refreshAccessTokenProcessStarted = true;
    this._refreshAccessTokenInterval = setInterval(() => {
      this.refreshAccessTokenNow();
    }, 10 * 60 * 1000);
  }

  public logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('AdminTokens');
    this._isLoggedIn = false;
    clearInterval(this._refreshAccessTokenInterval);
    this.router.navigate(['/login']).catch();
  }

  private refreshAccessTokenNow(): void {
    const currentUser = this.tokens;
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(currentUser.accessToken);
    const data = {
      id: this.uuidService.uuid,
      tokenId: decodedToken.tokenId,
      refreshToken: currentUser.refreshToken
    };
    this.http.post<any>(`${ this.api.URLS.refreshToken }`, data).subscribe(user => {
      if (user && user.accessToken) {
        const updatedUserTokens = {
          ...currentUser,
          accessToken: user.accessToken
        };
        this.saveInLocalTokenAndUser(updatedUserTokens);
      }
      return user;
    }, () => {
      this.logout();
    });
  }

  public refreshAccessTokenInInterceptor(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const currentUser = this.tokens;
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(currentUser.accessToken);
    const data = {
      id: this.uuidService.uuid,
      tokenId: decodedToken.tokenId,
      refreshToken: currentUser.refreshToken
    };
    return this.http.post(this.api.URLS.refreshToken, data).pipe(switchMap((response: any) => {
      const updatedUserTokens = {
        ...currentUser,
        accessToken: response.accessToken
      };
      localStorage.setItem('token', JSON.stringify(updatedUserTokens));
      const clone = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${ response.accessToken }`)
      });
      return next.handle(clone);
    }), catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        return next.handle(request);
      }
    }));
  }

  public checkActivateToken(token: string): Observable<any> {
    return this.http.put(`${this.api.URLS.checkActivateToken}`, { token }).pipe(map(response => {
      return response;
    }));
  }

  public checkResetToken(token: string): Observable<any> {
    return this.http.put(`${this.api.URLS.checkResetToken}`, { token }).pipe(map(response => {
      return response;
    }));
  }

  public recover(username: string): Observable<any> {
    return this.http.put<any>(`${ this.api.URLS.passwordRecover }`, { email: username })
      .pipe(map(response => {
        return response;
      }));
  }

  public reset(token: string, password: string): Observable<any> {
    const data = {
      token,
      password
    };
    return this.http.put<any>(`${ this.api.URLS.resetPassword }`, data).pipe(map(response => {
      return response;
    }));
  }

  public activate(token: string, password: string, retypePassword: string): Observable<any> {
    const data = {
      token,
      password,
      retypePassword
    };
    return this.http.put<any>(`${ this.api.URLS.activateAccount }`, data).pipe(map(response => {
      return response;
    }));
  }

  public emulateUser(email): Observable<any> {
    const tokens = this.tokens;
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(tokens.accessToken);
    const data = {
      id: decodedToken.tokenId,
      email
    };
    return this.http.post<any>(this.api.URLS.emulateUser, data).pipe(map(user => {
      if (user && user.accessToken) {
        localStorage.setItem('AdminTokens', localStorage.getItem('token'));
        this.updateFrontOnLogin(user);
      }
      return user;
    }));
  }

  public exitEmulatingUser(): Observable<any> {
    const tokens = this.tokens;
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(tokens.accessToken);
    const data = {
      id: decodedToken.tokenId
    };
    return this.http.post<any>(this.api.URLS.emulateUserExit, data).pipe(map(user => {
      if (user && user.accessToken) {
        localStorage.removeItem('AdminTokens');
        this.updateFrontOnLogin(user);
      }
      return user;
    }));
  }

  public getAdminUser(): object {
    const jwtHelper = new JwtHelperService();
    const tokens = JSON.parse(localStorage.getItem('AdminTokens'));
    if (!tokens) {
      return null;
    }
    const decodedToken = jwtHelper.decodeToken(tokens.accessToken);
    return decodedToken || null;
  }

  public get userRole(): string {
    const user = this.currentUser;
    return user ? user.role : null;
  }

  public get fullName(): string {
    const currentUser = this.currentUser;
    if (currentUser && currentUser.name) {
      return currentUser.name + ' ' + currentUser.surname;
    } else {
      return 'n/a';
    }
  }

  public get id(): string {
    const currentUser = this.currentUser;
    return currentUser ? currentUser.userId : null;
  }

  public get isRoot(): boolean {
    const currentUser = this.currentUser;
    return (currentUser && currentUser.role === 'Root') || false;
  }
}
