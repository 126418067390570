<div class="login-page">
  <div class="login-page-wrapper">
    <div class="login-page-header">
      <a routerLink="/" [attr.aria-label]="'front.SITE_NAME' | translate" class="login-page-header__logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="47.273" height="10" viewBox="0 0 47.273 10" fill="currentColor">
          <path class="a" d="M225.529,117.873c0,.246-.02.474-.039.7a.464.464,0,0,1-.52.435h-6.434a2.182,2.182,0,0,0,2.254,
                1.572,2.329,2.329,0,0,0,1.425-.474.9.9,0,0,1,.6-.246l1.85-.019c.347,0,.54.227.386.53a4.426,4.426,0,0,1-4.3,2.5,
                5,5,0,1,1-.077-10A4.79,4.79,0,0,1,225.529,117.873Zm-2.877-1.023a1.925,1.925,0,0,0-2-1.458,2.045,2.045,0,0,0-2.13,1.458Z"
                transform="translate(-215.509 -112.873)"/>
          <path class="a" d="M230.53,113.611a.433.433,0,0,0-.482-.473h-1.927a.433.433,0,
                0,0-.481.473v8.523a.433.433,0,0,0,.481.473h1.927a.433.433,0,0,0,.482-.473Z" transform="translate(-215.693 -112.873)"/>
          <path class="a" d="M237.749,117.873a5.107,5.107,0,1,1,5.106,5A5.007,5.007,0,0,1,237.749,117.873Zm7.322,0a2.216,2.216,0,1,
                0-2.215,2.235A2.141,2.141,0,0,0,245.071,117.873Z" transform="translate(-215.846 -112.873)"/>
          <path class="a" d="M236.376,115.392a2.216,2.216,0,1,0-2.215,2.235A2.142,2.142,0,0,0,236.376,115.392Z"
                transform="translate(-215.758 -112.873)"/>
          <path class="a" d="M260.324,113.005c1.889,0,2.987,1.175,2.987,
                3.352v5.909a.433.433,0,0,1-.482.473H260.9a.433.433,0,0,1-.482-.473V116.6a.886.886,0,0,0-1-.985c-.579,
                0-1.118.284-1.118,1.212v5.436a.432.432,0,0,1-.482.473h-1.927a.432.432,0,0,1-.482-.473V116.6a.886.886,0,
                0,0-1-.985c-.578,0-1.117.284-1.117,1.212v5.436a.433.433,0,0,1-.482.473h-1.927a.432.432,0,0,1-.481-.473v-8.523a.433.433,
                0,0,1,.481-.473h1.927a.433.433,0,0,1,.482.473v.387a2.6,2.6,0,0,1,2.216-1.125,2.532,2.532,0,0,1,2.233,1.127,3.392,
                3.392,0,0,1,2.583-1.127Z" transform="translate(-216.038 -112.873)"/>
        </svg>
      </a>
      <h1 class="login-page-header__title">{{ 'front.LOGIN_PAGE_TITLE' | translate }}</h1>
    </div>
    <div class="login-page-container">
      <form class="login-page-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email" class="form-label">{{ 'front.USERNAME_EMAIL' | translate }}</label>
          <input id="email"
                 type="text"
                 class="form-control form-control__input"
                 formControlName="email"
                 [class.invalid]="loginForm.touched && f.email.touched && f.email.errors" />
        </div>
        <div class="form-group">
          <label for="password" class="form-label">{{ 'front.PASSWORD' | translate }}</label>
          <input id="password"
                 type="password"
                 class="form-control form-control__input"
                 formControlName="password"
                 [class.invalid]="loginForm.touched && f.password.touched && f.password.errors" />
        </div>
        <div class="form-actions">
          <button type="submit"
                  class="form-submit"
                  [class.erom-spinner]="submitting"
                  [disabled]="submitting">{{ 'front.LOG_IN' | translate }}</button>
        </div>
      </form>
      <div class="login-page-footer">
        <a [routerLink]="['/forgot-password']">{{ 'navigation.FORGOT_PASSWORD' | translate }}</a>
      </div>
    </div>
  </div>
</div>
