import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EromBulkPasteComponent } from './erom-bulk-paste.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    EromBulkPasteComponent
  ],
  providers: [],
  exports: [EromBulkPasteComponent]
})
export class EromBulkPasteModule {}
