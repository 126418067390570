<div class="erom-table" [class.mobile-switch]="tableShouldSwitchMobile" *ngIf="columns && columns.length">

  <div class="erom-table-head-panel">

    <div class="erom-table-filters-container" role="region" aria-labelledby="filtersSectionLabel">
      <div class="erom-table-bulk-actions-label" id="filtersSectionLabel">
        {{ 'table.FILTERS' | translate }}
      </div>
      <button class="erom-table-filters-mobile-button"
              aria-haspopup="true"
              [class.filters-applied]="activeFilters.length"
              [attr.aria-expanded]="mobileFiltersActive === true"
              [attr.aria-controls]="'tableFiltersContainer'"
              (click)="activateMobileFilters()">
        <fa-icon [icon]="['fas', 'filter']" class="filter"></fa-icon>
        <ng-container *ngIf="activeFilters.length; else noActiveFiltersButtonLabel">
          <ng-container *ngIf="activeFilters.length > 1; else onlyOneFilterApplied">
            {{ 'table.FILTERS_APPLIED_COUNT' | translate : { count: activeFilters.length } }}
          </ng-container>
          <ng-template #onlyOneFilterApplied>
            {{ 'table.ONE_FILTER_APPLIED' | translate }}
          </ng-template>
        </ng-container>
        <ng-template #noActiveFiltersButtonLabel>
          {{ 'table.APPLY_FILTERS' | translate }}
        </ng-template>
        <fa-icon [icon]="['fas', 'chevron-down']" class="chevron-down"></fa-icon>
      </button>
      <div class="erom-table-filters"
           *ngIf="activeFilters.length"
           [class.hidden-filters]="tableShouldSwitchMobile && !mobileFiltersActive">
        <div class="erom-table-filters-head">
          <div class="erom-table-filters-info">
            <fa-icon [icon]="['fas', 'filter']"></fa-icon>{{ 'table.RESULTS' | translate : { count: activeFilters.length } }}
          </div>
          <button class="erom-table-filters-clear"
                  (click)="clearAllFilters()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon><span>{{ 'table.CLEAR_ALL_FILTERS' | translate }}</span>
          </button>
        </div>
        <div class="erom-table-filters-body">
          <div class="erom-table-filters-group"
               *ngFor="let filter of groupedActiveFilters">
            <div class="erom-table-filters-group-label">
              {{ filter.label }}: {{ filter.children.length }}
              <button class="erom-table-filters-clear"
                      [attr.aria-label]="'table.CLEAR_FILTERS_GROUP' | translate : { group: filter.label }"
                      (click)="clearGroupedFilter(filter)">
                <span>{{ 'table.CLEAR_FILTERS' | translate }}</span>
              </button>
            </div>
            <div class="erom-table-filters-group-items">
              <div class="erom-table-filters-group-item" *ngFor="let child of filter.children">
                {{ child.label }}
                <button class="erom-table-filters-group-item-clear"
                        [attr.aria-label]="'table.CLEAR_FILTER' | translate : {filter: child.label}"
                        (click)="clearFilter(child.originalFilter)">
                  <fa-icon [icon]="['fas', 'times']"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="erom-table-bulk-actions" [class.no-bulk-actions]="!bulkActions">
      <div class="erom-table-bulk-actions-selectors"
           *ngIf="bulkActions"
           role="region"
           aria-labelledby="bulkActionsSectionLabel">
        <div class="erom-table-bulk-actions-label" id="bulkActionsSectionLabel">
          {{ 'table.BULK_ACTIONS' | translate }}
        </div>
        <div class="erom-table-bulk-actions-ticker">
          <input id="bulk-check"
                 type="checkbox"
                 [checked]="bulkRowsSelectedAllCount > 0 || bulkRowsSelectedAll"
                 (change)="bulkActionCheckAll($event)"/>
          <label for="bulk-check">
            <fa-icon [icon]="['fas', bulkTickerIconClass]" *ngIf="bulkTickerIconClass"></fa-icon>
            <ng-container *ngIf="bulkRowsSelectedAll; else bulkRowsSelectedCustom">
              {{ 'table.BULK_SELECTED_ALL' | translate }}
            </ng-container>
            <ng-template #bulkRowsSelectedCustom>
              <ng-container *ngIf="!bulkRowsSelectedAllCount > 0; else bulkSelected">
                {{ 'table.BULK_SELECT_ALL' | translate }}
              </ng-container>
              <ng-template #bulkSelected>
                {{ 'table.BULK_SELECTED_COUNT' | translate : {'count': bulkRowsSelectedAllCount} }}
              </ng-template>
            </ng-template>
          </label>
        </div>
        <erom-select class="erom-table-bulk-actions-selector"
                     type="gray"
                     id="bulkSelector"
                     [searchable]="false"
                     [clearOption]="false"
                     [options]="bulkActions"
                     [label]="'table.BULK_CHOOSE_OPTION' | translate"
                     [aria-label]="'table.BULK_CHOOSE_OPTION' | translate"
                     [placeholder]="'table.BULK_CHOOSE_OPTION' | translate"
                     (optionSelected)="initializeBulkAction($event)"></erom-select>
        <button class="erom-table-bulk-actions-submit"
                *ngIf="bulkAction && bulkRowsSelectedAllCount > 0"
                (click)="submitBulkAction($event)">
          <fa-icon [icon]="['fas', 'check']"></fa-icon>{{ 'table.APPLY' | translate }}
        </button>
      </div>
      <div class="erom-table-bulk-actions-buttons" role="region" aria-labelledby="optionsSectionLabel">
        <div class="erom-table-bulk-actions-label" id="optionsSectionLabel">
          {{ 'table.OPTIONS' | translate }}
        </div>
        <div class="erom-table-bulk-actions-buttons-group" #moreButtonsSelector>
          <button *ngIf="!getOptionByKey('disableAddNew')" (click)="addNewRow()">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            {{ getOptionByKey('addNewRowText') || ('table.ADD_NEW_ROW' | translate) }}
          </button>
          <div class="erom-table-bulk-actions-buttons-group--more"
               *ngIf="!getOptionByKey('disableCsvExport')">
            <button type="button"
                    id="tableMoreOptionsTrigger"
                    class="erom-table-bulk-actions-buttons-group--more__trigger more-options-btn"
                    aria-haspopup="true"
                    aria-controls="tableMoreOptionsList"
                    aria-labelledby="tableMoreOptionsTitle"
                    (click)="activateMoreOptions($event, tableMoreOptionsList)"
                    [attr.aria-expanded]="moreOptionsExpanded">
              <span id="tableMoreOptionsTitle" class="erom-table-visually-hidden">{{ 'table.MORE_OPTIONS' | translate }}</span>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-vertical" class="svg-inline--fa fa-ellipsis-vertical fa-w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                <path fill="currentColor" d="M128 64c0-35.39-28.62-64-64-64S0 28.61 0 64s28.62 64 64 64S128 99.39 128 64zM128 256c0-35.39-28.62-64-64-64S0 220.6 0 256s28.62 64 64 64S128 291.4 128 256zM128 448c0-35.39-28.62-64-64-64s-64 28.61-64 64s28.62 64 64 64S128 483.4 128 448z"></path>
              </svg>
            </button>
            <div id="tableMoreOptionsList"
                 #tableMoreOptionsList
                 class="erom-table-bulk-actions-buttons-group--more__list"
                 role="region"
                 aria-labelledby="tableMoreOptionsTitle"
                 [hidden]="!moreOptionsExpanded"
                 [attr.aria-expanded]="moreOptionsExpanded"
                 [attr.aria-hidden]="!moreOptionsExpanded">
              <button *ngIf="!getOptionByKey('disableCsvExport')"
                      class="list-btn"
                      [disabled]="!tableRows.length"
                      [class.inactive]="!tableRows.length"
                      (click)="csvExport()">
                <fa-icon [icon]="['fas', 'cloud-download-alt']"></fa-icon>
                <span>{{ 'table.EXPORT_TABLE' | translate }}</span>
              </button>
              <button *ngIf="!getOptionByKey('disableRowsExport')"
                      class="list-btn"
                      [disabled]="!tableRows.length"
                      [class.inactive]="!tableRows.length"
                      (click)="rowsExport()">
                <fa-icon [icon]="['fas', 'file-export']"></fa-icon>
                <span>{{ getOptionByKey('exportRowsText') || ('table.EXPORT_ROWS' | translate) }}</span>
              </button>
              <div *ngIf="!getOptionByKey('disableRowsImport')"
                   class="action-btn list-btn">
                <input type="file"
                       id="importTableRows"
                       #importTableRows
                       accept="application/JSON"
                       (change)="rowsImport($event)" />
                <label for="importTableRows"
                       role="button"
                       tabindex="0"
                       (keydown.space)="$event.preventDefault(); importTableRows.click()"
                       (keydown.enter)="$event.preventDefault(); importTableRows.click()">
                  <fa-icon [icon]="['fas', 'file-import']"></fa-icon>
                  <span>{{ getOptionByKey('importRowsText') || ('table.IMPORT_ROWS' | translate) }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="erom-table-body" [class.contains-totals]="totals">
    <table [attr.role]="tableShouldSwitchMobile ? 'grid' : 'table'">
      <thead [id]="tableShouldSwitchMobile ? 'tableFiltersContainer' : ''"
             [attr.role]="tableShouldSwitchMobile ? 'region' : ''"
             [class.hidden-filters]="tableShouldSwitchMobile && !mobileFiltersActive">
      <tr class="erom-table-head">
        <ng-container *ngFor="let column of columns; let i = index">
          <th class="erom-table-head__cell"
              (click)="headColumnActioned(column, i)"
              [ngStyle]="column.styles || {}"
              [ngClass]="columnClasses(column)">
            <div class="erom-table-head__cell--body">
              <ng-container [ngSwitch]="column.settings.type">
                <ng-container *ngSwitchCase="'Search'">
                  <label class="erom-table-visually-hidden" [for]="'erom-table-search-' + column.value">{{ column.label }}</label>
                  <input type="search"
                         class="erom-table-search"
                         autocomplete="off"
                         [id]="'erom-table-search-' + column.value"
                         [class.active]="isFilterActive(column)"
                         [name]="column.value"
                         [value]="activeFilterValue(column)"
                         (input)="activateFilter($event, column)"
                         [placeholder]="column.label" />
                  <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'Select'">
                  <erom-select
                    type="gray"
                    [id]="'erom-table-column-select-' + i"
                    [name]="column.value"
                    [multiple]="column.settings.multiple || false"
                    [checkbox]="column.settings.checkbox || false"
                    [searchable]="column.settings.hasSearch || false"
                    [searchPlaceholder]="column.label"
                    [closeOnSelect]="column.settings.closeOnSelect"
                    [options]="column.options"
                    [customTitle]="tableHeadSelectTitle(column)"
                    [aria-label]="column.settings.placeholder"
                    [placeholder]="column.settings.placeholder"
                    (optionSelected)="activateFilter($event, column)"></erom-select>
                </ng-container>
                <ng-container *ngSwitchCase="'Order'">
                  <div class="erom-table-head__cell--flex">
                    <span class="erom-table-head__cell--text">{{ column.label }}</span>
                    <div class="erom-table-head__cell--buttons"
                         [attr.aria-label]="column.label"
                         role="group">
                      <button class="order-ascending"
                              (click)="activateOrder(column, 'ASC')"
                              [attr.aria-label]="'table.ORDER_ASCENDING' | translate"
                              [ngClass]="{'active': isOrderActive(column, 'ASC')}">
                        <fa-icon [icon]="['fas', 'chevron-up']"></fa-icon>
                      </button>
                      <button class="order-descending"
                              (click)="activateOrder(column, 'DESC')"
                              [attr.aria-label]="'table.ORDER_DESCENDING' | translate"
                              [ngClass]="{'active': isOrderActive(column, 'DESC')}">
                        <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="erom-table-head__cell--text">
                    {{ column.label }}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </th>
        </ng-container>
      </tr>
      </thead>
      <tbody *ngIf="tableRows.length">
      <tr class="erom-table-row"
          [ngClass]="{ 'has-hover': row.id, 'checked-row': row.selected || bulkRowsSelectedAll }"
          [class]="'row_' + (rowIndex % 2 === 0 ? 'odd' : 'even')"
          (click)="initializeTableRowAction($event, row, {'value': 'edit'}, true)"
          *ngFor="let row of tableRows; let rowIndex = index">
          <td *ngFor="let column of columns; let columnIndex = index"
              [class.empty]="column.settings.type === 'Checkbox'"
              [attr.data-label]="column.label">
            <ng-container *ngIf="column.settings.type === 'Toggle'; else nonToggleCell">
              <input type="checkbox"
                     [id]="'eromTableSwitch-' + rowIndex"
                     [checked]="row.columns[column.value]"
                     (change)="toggleTableRow($event, row.id)"
                     class="erom-table-row__toggle erom-table-visually-hidden" />
              <label [for]="'eromTableSwitch-' + rowIndex">
                {{ row.columns[column.value] ? (getOptionByKey('rowUnToggleTooltipText') ||
                ('table.UN_TOGGLE_ROW' | translate)) : (getOptionByKey('rowToggleTooltipText') || ('table.TOGGLE_ROW' | translate)) }}
              </label>
            </ng-container>
            <ng-template #nonToggleCell>
              <ng-container *ngIf="column.settings.type === 'Actions' && row.actions; else nonActionsCell">
                <ul class="erom-table-row-actions">
                  <li *ngFor="let action of row.actions">
                    <button [attr.aria-label]="action.label" (click)="initializeTableRowAction($event, row, action, false)">
                      <fa-icon [icon]="[action.icon.type || 'fas', action.icon.name]"
                               [spin]="action.icon.spin || false"
                               [styles]="action.icon.styles"
                               *ngIf="action.icon && action.icon.name"></fa-icon>
                      <span class="erom-table-tooltip">{{ action.label }}</span>
                    </button>
                  </li>
                </ul>
              </ng-container>
            </ng-template>
            <ng-template #nonActionsCell>
              <ng-container *ngIf="column.settings.type === 'Checkbox'; else nonSelectTableCell">
                <div class="erom-table-row-check">
                  <input [id]="'bulk-item-check-' + rowIndex"
                         [attr.aria-label]="row.selected || bulkRowsSelectedAll ? (getOptionByKey('rowUnSelectTooltipText') ||
                                            ('table.BULK_ROW_UNSELECT' | translate)) : (getOptionByKey('rowSelectTooltipText') || ('table.BULK_ROW_SELECT' | translate))"
                         [checked]="row.selected || bulkRowsSelectedAll"
                         (change)="bulkActionCheckSingleRow($event, row)"
                         type="checkbox" />
                  <label [for]="'bulk-item-check-' + rowIndex" [class.empty]="!column.value">
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    <span class="erom-table-tooltip">
                      <ng-container *ngIf="row.selected || bulkRowsSelectedAll; else selectTooltipText">
                        {{ getOptionByKey('rowUnSelectTooltipText') || ('table.BULK_ROW_UNSELECT' | translate) }}
                      </ng-container>
                      <ng-template #selectTooltipText>
                        {{ getOptionByKey('rowSelectTooltipText') || ('table.BULK_ROW_SELECT' | translate) }}
                      </ng-template>
                    </span>
                    <ng-container *ngIf="column.value">
                      <span [innerHTML]="convertData(row.columns[column.value])"></span>
                    </ng-container>
                  </label>
                </div>
              </ng-container>
              <ng-template #nonSelectTableCell>
                <div class="erom-table-row__cell" [class.has-tooltip]="hasTooltip(row.columns[column.value], convertData(row.columns[column.value], true))">
                  <span [innerHTML]="convertData(row.columns[column.value])"></span>
                  <span class="erom-table-tooltip"
                        *ngIf="hasTooltip(row.columns[column.value], convertData(row.columns[column.value], true))"
                        [innerHTML]="convertData(row.columns[column.value], true)"></span>
                </div>
              </ng-template>
            </ng-template>
          </td>
      </tr>


      </tbody>
      <tfoot *ngIf="totals">
      <tr>
        <ng-container *ngFor="let column of columns">
          <td [ngClass]="totals[column.value] ? 'has-data' : ''">
            <ng-container *ngIf="totals[column.value]">
              <span class="erom-table-footer-label">{{ column['label'] }}</span>
              {{ totals[column.value] }}
            </ng-container>
          </td>
        </ng-container>
      </tr>
      </tfoot>
    </table>

    <ng-container *ngIf="!tableRows.length && !loading">
      <div class="erom-table-no-content">
        {{ 'table.NOTHING_YET' | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="loadMore && (offset + ppp < limit && !loading)">
      <div class="erom-table-load-more-wrap" inViewport (inViewportAction)="infiniteLoadInit($event)" [debounce]="10"
           *ngIf="offset + ppp <= limit && !loading">
        <div class="erom-table-load-more">{{ 'table.LOADING_MORE' | translate }}</div>
      </div>
    </ng-container>

  </div>

  <ng-container *ngIf="!loadMore && (offset + ppp < limit && !loading)">
    <div class="erom-table-load-more-wrap">
      <button class="erom-table-load-more-btn" (click)="loadMoreAction()">{{ 'table.LOAD_MORE' | translate }}</button>
    </div>
  </ng-container>

</div>

<ng-container *ngIf="loading">
  <div class="erom-table-loading">
    <div class="ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div>
      {{ 'table.TABLE_LOADING' | translate }}
    </div>
  </div>
</ng-container>
