import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EromTableActiveFilter } from '../_interfaces';

@Injectable()
export class EromTableService {

  /**
   * Are URL query filters enabled.
   */
  private _tableFiltersEnabled: boolean;

  constructor(
    private route: ActivatedRoute
  ) {
    this._tableFiltersEnabled = true;
  }

  /**
   * Check if a string is a valid json.
   */
  private static parseJSON(jsonString: string): any {
    let json;
    try {
      json = JSON.parse(jsonString);
    } catch (e) {
      json = false;
    }
    return json;
  }

  /**
   * Generate URL query parameters based on filters array.
   */
  public generateFilterParams(filters: EromTableActiveFilter[], initialLoad: boolean): any {
    const filterParams = {};
    if (initialLoad) {
      const currentFilters = this.currentFilters;
      if (!currentFilters) {
        return {};
      }
      Object.keys(currentFilters).forEach(key => {
        if (currentFilters[key].toString().includes(',')) {
          const filterArray = currentFilters[key].split(',');
          for (let i = 0; i < filterArray.length; i++) {
            if (filterParams[key]) {
              filterParams[key].push(filterArray[i].toString());
            } else {
              filterParams[key] = [filterArray[i].toString()];
            }
          }
        } else {
          filterParams[key] = currentFilters[key].toString();
        }
      });
    } else {
      for ( const f of filters ) {
        if (f.multiple) {
          if ( filterParams[f.column.value] ) {
            filterParams[f.column.value].push(f.value.toString());
          } else {
            filterParams[f.column.value] = [f.value.toString()];
          }
        } else {
          filterParams[f.column.value] = f.value.toString();
        }
      }
    }
    return filterParams;
  }

  /**
   * Download CSV.
   */
  public downloadCSV(data: string, fileName: string): void {
    const downloadLink = document.createElement('a');
    const blob = new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' });
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.hidden = true;
    downloadLink.download = `${fileName}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  /**
   * Download JSON.
   */
  public downloadJSON(data: Array<any>, fileName: string): void {
    const downloadLink = document.createElement('a');
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.hidden = true;
    downloadLink.download = `${fileName}.json`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  /**
   * Get active URL parameters.
   */
  public get currentFilters(): object {
    if (!this._tableFiltersEnabled) {
      return null;
    }
    const queryParams = Object.assign({}, this.route.snapshot.queryParams);
    if (!queryParams.tableFilters) {
      return null;
    }
    const tableFilters = EromTableService.parseJSON(queryParams.tableFilters);
    if (!tableFilters) {
      return null;
    }
    return tableFilters;
  }

  /**
   * Set tableFiltersEnabled true/false.
   */
  public set tableFiltersEnabled(enabled: boolean) {
    this._tableFiltersEnabled = enabled;
  }
}
