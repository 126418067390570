import { Injectable } from '@angular/core';
import { MenuItemInterface } from '../_interfaces';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class BreadcrumbsService {

  private static ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';

  private _breadcrumbs: MenuItemInterface[];

  constructor() {
  }

  private static isNullOrUndefined(label): boolean {
    return label === null || label === undefined;
  }

  private buildRouteBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItemInterface[] = []) {
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data[BreadcrumbsService.ROUTE_DATA_BREADCRUMB] : null;
    let path = route.routeConfig ? route.routeConfig.path : '/';

    const parameterPattern = /^:/i;
    const isParameter = parameterPattern.test(path);

    let nextUrl = url;
    if (path !== '/') {
      const pathParameter = path.replace(parameterPattern, '');
      path = isParameter && route.snapshot.params[pathParameter] ? route.snapshot.params[pathParameter] : path;
      nextUrl = `${url}${path}/`;
    }

    const breadcrumb = {
      label: label,
      url: nextUrl
    };

    let newBreadcrumbs = [...breadcrumbs];

    if (!BreadcrumbsService.isNullOrUndefined(label)) {
      newBreadcrumbs = [ ...breadcrumbs, breadcrumb ];
    }

    if (route.firstChild) {
      return this.buildRouteBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

  public buildRouteBreadcrumbs(activatedRoute: ActivatedRoute): void {
    this._breadcrumbs = this.buildRouteBreadCrumb(activatedRoute);
  }

  get breadcrumbs(): MenuItemInterface[] {
    return this._breadcrumbs;
  }
}
