import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { markAsTouched } from '../../_helpers/common';
import { API } from '../../_store';
import { HttpClient } from '@angular/common/http';
import { PasswordValidation, RepeatPasswordValidator } from '../../_validators';

@AutoUnsubscribe()
@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {

  public activateForm: UntypedFormGroup;

  private _token: string;
  private _invalidToken: boolean;
  private _tokenCheckInProgress: boolean;
  private _returnUrl: string;
  private _submitting: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private api: API,
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']).catch();
    }
    this.activateForm = new UntypedFormGroup({
      password: new UntypedFormControl(null, PasswordValidation),
      retypePassword: new UntypedFormControl(null, PasswordValidation)
    }, RepeatPasswordValidator );
  }

  public ngOnInit(): void {
    this._returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || '/';
    this._token = this.activatedRoute.snapshot.params.token;
    this.checkActivationToken();
  }

  public ngOnDestroy(): void {
  }

  private checkActivationToken(): void {
    this._tokenCheckInProgress = true;
    if (!this._token) {
      this._invalidToken = true;
    }
    this.authService.checkActivateToken(this._token).pipe(first()).subscribe(response => {
      this._invalidToken = !response.active;
      if (this._invalidToken) {
        location.href = `${location.origin}/498`;
      } else {
        this._tokenCheckInProgress = false;
      }
    }, () => {
      this._invalidToken = true;
      this._tokenCheckInProgress = false;
    });
  }

  public onSubmit(): void {

    markAsTouched(this.activateForm);

    if (this.activateForm.invalid) {
      return;
    }

    this._submitting = true;

    this.authService.activate(this._token, this.f.password.value, this.f.retypePassword.value).pipe(first()).subscribe(response => {
      this.authService.login(response.email, this.f.password.value).pipe(first()).subscribe(() => {
        this._submitting = false;
        this.router.navigate([ this._returnUrl ]).catch();
      }, error => {
        this._submitting = false;
        console.log(error);
      });
    }, error => {
      this._submitting = false;
      console.log(error);
    });
  }

  public get f(): any {
    return this.activateForm.controls;
  }

  public get submitting(): boolean {
    return this._submitting;
  }

  public get invalidToken(): boolean {
    return this._invalidToken;
  }

  public get tokenCheckInProgress(): boolean {
    return this._tokenCheckInProgress;
  }

}
