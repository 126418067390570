import {
  AfterViewInit, ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output, QueryList,
  SimpleChange,
  SimpleChanges, ViewChild, ViewChildren
} from '@angular/core';
import {
  EromTableActiveFilter,
  EromTableColumn,
  EromTableFilterGroup,
  EromTableRow,
  EromTableSelectOption,
  EromTableOptions,
  EromTableActionInterface,
  EromTableRowColumn
} from '../_interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EromSelectComponent } from '../../erom-select/component/erom-select.component';
import { EromTableService } from '../_services/erom-table.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'erom-table',
  templateUrl: './erom-table.component.html',
  styleUrls: ['./erom-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EromTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  /**
   * Table columns with settings.
   */
  @Input() columns: EromTableColumn[];

  /**
   * Table rows.
   */
  @Input() set rows(payload: EromTableRow[]) {
    if (this._bulkRowsSelectedAll) {
      this._tableRows = payload.map(r => {
        this._bulkRowsSelected.push(r.id);
        return {
          ...r,
          selected: true
        };
      });
    } else {
      this._tableRows = payload;
    }
  }

  /**
   * Table actions.
   */
  @Input() actions: EromTableActionInterface[];

  /**
   * Table bulk actions.
   */
  @Input() bulkActions: EromTableSelectOption[];

  /**
   * Table has load more option.
   */
  @Input() loadMore: boolean;

  /**
   * Table data loading.
   */
  @Input() loading: boolean;

  /**
   * Table data loading.
   */
  @Input() totals: EromTableSelectOption[];

  /**
   * Table all rows count.
   */
  @Input() limit;

  /**
   * Table filtered rows count.
   */
  @Input() filteredItemsQty: number;

  /**
   * Table options.
   */
  @Input() options: EromTableOptions;

  /**
   * Emit event when filters was changed.
   */
  @Output() filtersChangeEvent = new EventEmitter();

  /**
   * Emit event when table item was actioned.
   */
  @Output() tableItemActionEvent = new EventEmitter();

  /**
   * Emit event when table bulk action was actioned.
   */
  @Output() bulkActionInit = new EventEmitter();

  /**
   * Emit event when table add new row action was actioned.
   */
  @Output() addNewRowEvent = new EventEmitter();

  /**
   * Emit event when table csv export action was actioned.
   */
  @Output() csvExportEvent = new EventEmitter();

  /**
   * Emit event when table rows export action was actioned.
   */
  @Output() rowsExportEvent = new EventEmitter();

  /**
   * Emit event when table rows import action was actioned.
   */
  @Output() rowsImportEvent = new EventEmitter();

  /**
   * Emit event when table toggle action was actioned.
   */
  @Output() toggleEvent = new EventEmitter();

  /**
   * Query the DOM for multiple EromSelectComponent elements and return a QueryList.
   */
  @ViewChildren(EromSelectComponent) selectors: QueryList<any>;

  @ViewChild('moreButtonsSelector') moreButtonsSelector: ElementRef;

  /**
   * Table active filters.
   */
  private _activeFilters: EromTableActiveFilter[];

  /**
   * Table selected rows for bulk action.
   */
  private _bulkRowsSelected: Array<any>;

  /**
   * Table all rows bulk selected.
   */
  private _bulkRowsSelectedAll: boolean;

  /**
   * Table all rows bulk selected count.
   */
  private _bulkRowsSelectedAllCount: number;

  /**
   * Table bulk action selected.
   */
  private _bulkAction: EromTableSelectOption;

  /**
   * Table input timer before filters changed event emit.
   */
  private _inputTimer: number;

  /**
   * Active table head index.
   */
  private _activeHeadColumn: number;

  /**
   * Listen to the filters change event.
   */
  private _filtersChangeEventSubscription: Subscription;

  /**
   * Window width after window resize.
   */
  private _windowWidth: number;

  /**
   * Mobile filters dropdown state.
   */
  private _mobileFiltersActive: boolean;

  /**
   * More options state.
   */
  private _moreOptionsExpanded: boolean;

  private _tableRows: EromTableRow[];

  /**
   * Time to wait (in ms) before input changed event emit.
   */
  readonly _inputTimeout: number;

  /**
   * Time to wait before DOM checks.
   */
  readonly _domModifiersTimeout: number;

  /**
   * Posts per page.
   */
  public ppp: number;

  /**
   * Request offset.
   */
  public offset: number;

  /**
   * Request limit.
   */
  public requestLimit: number;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private tableService: EromTableService,
    private sanitized: DomSanitizer
  ) {
    this.limit = 30;
    this.ppp = 30;
    this.offset = 0;
    this.requestLimit = 30;
    this._activeHeadColumn = null;
    this._inputTimeout = 400;
    this._activeFilters = [];
    this._bulkAction = null;
    this._bulkRowsSelected = [];
    this._bulkRowsSelectedAllCount = 0;
    this._domModifiersTimeout = 2500;
    this._windowWidth = window.innerWidth;
  }

  /**
   * Cut row text if maxCart is set.
   */
  private static maybeStringCutNeeded(row: EromTableRowColumn, text: string): string {
    const chars = row.settings.maxChars ? row.settings.maxChars : 30;
    if (text.length > chars) {
      return text.substr(0, chars) + '...';
    }
    return text;
  }

  /**
   * Make sure that head inputs are not smaller them their placeholders.
   */
  private static matchInputsWidthByPlaceholder(timeout: number): void {
    setTimeout(() => {
      const inputs = document.querySelectorAll('input.erom-table-search, .erom-select-placeholder input');
      for (let i = 0; i < inputs.length; i++) {
        const input: HTMLElement = inputs[i] as HTMLElement;
        input.style.minWidth = EromTableComponent.inputPlaceholderWidth(input);
      }
    }, timeout);
  }

  /**
   * Calculate input width based on it's placeholder size plus 4px error rate.
   */
  private static inputPlaceholderWidth(input: HTMLElement): string {
    const tmp = document.createElement('span');
    const inputStyles = window.getComputedStyle(input, null);
    tmp.style.fontSize = inputStyles.getPropertyValue('font-size');
    tmp.style.fontFamily = inputStyles.getPropertyValue('font-family');
    tmp.style.letterSpacing = inputStyles.getPropertyValue('letter-spacing');
    tmp.style.fontWeight = inputStyles.getPropertyValue('font-weight');
    tmp.style.fontStyle = inputStyles.getPropertyValue('font-weight');
    tmp.style.visibility = 'hidden';
    tmp.innerHTML = input.getAttribute('placeholder')
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    document.body.appendChild(tmp);
    const inputSize = tmp.offsetWidth + parseInt(inputStyles.getPropertyValue('padding-left'), 10) + parseInt(inputStyles.getPropertyValue('padding-right'), 10);
    document.body.removeChild(tmp);
    return typeof inputSize === 'number' ? inputSize + 4 + 'px' : 'unset';
  }

  /**
   * Make sure that head drop-downs dont goes outside viewport.
   */
  private static selectorsGoOutsideViewport(selectors: QueryList<any>, timeout: number): void {
    setTimeout(() => {
      selectors.forEach(selector => {
        if (/^erom-table-column-select-/.test(selector.id)) {
          const dropdown = selector.dropdownMenuList.nativeElement;
          const bounding = dropdown.getBoundingClientRect();
          const rightPosition = (window.innerWidth || document.documentElement.clientWidth) - bounding.right;
          if (rightPosition < 0) {
            dropdown.parentElement.classList.add('right-align');
          }
        }
      });
    }, timeout);
  }

  /**
   * Make sure that head drop-downs dont goes outside viewport.
   */
  private static maybeMoreOptionsSelectorGoesOutsideViewport(trigger: HTMLElement, selector: HTMLElement): void {
    const bounding = trigger.getBoundingClientRect();
    const rightPosition = (window.innerWidth || document.documentElement.clientWidth) - bounding.right;
    if (rightPosition < 85) {
      selector.classList.add('right-align');
    } else {
      selector.classList.remove('right-align');
    }
  }

  ngOnInit(): void {
    EromTableComponent.matchInputsWidthByPlaceholder(this._domModifiersTimeout);
    this._filtersChangeEventSubscription = this.filtersChangeEvent.subscribe(() => {
      this.updateUrlFilters();
      this.updateSelectorsBasedOnFilters();
    });
    if (this.options && this.options.disableUrlQueryFilters) {
      this.tableService.tableFiltersEnabled = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const rows: SimpleChange = changes.rows;
    const columns: SimpleChange = changes.columns;
    if (rows && rows.previousValue !== rows.currentValue) {
      EromTableComponent.matchInputsWidthByPlaceholder(this._domModifiersTimeout);
      // this._bulkAction = null;
      // this._bulkRowsSelected = [];
    }
    if (columns && columns.previousValue !== columns.currentValue) {
      this.activateUrlFilters();
      setTimeout(() => {
        this.inverseChildrenOrder('.erom-table-bulk-actions');
        this.inverseChildrenOrder('.erom-table-head-panel');
      });
    }
  }

  ngOnDestroy(): void {
    this._filtersChangeEventSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.updateSelectorsBasedOnFilters();
    EromTableComponent.selectorsGoOutsideViewport(this.selectors, this._domModifiersTimeout);
  }

  public activateMoreOptions(event: any, selector: HTMLElement): void {
    this._moreOptionsExpanded = !this._moreOptionsExpanded;
    EromTableComponent.maybeMoreOptionsSelectorGoesOutsideViewport(event.target, selector);
  }

  /**
   * Update table head selectors based on active filters.
   */
  private updateSelectorsBasedOnFilters(): void {
    if (!this.selectors) {
      return;
    }
    this.selectors.forEach(selector => {
      if (/^erom-table-column-select-/.test(selector.id)) {
        const thisSelectorFilters = this._activeFilters.filter((filter) => filter.column.value === selector.name);
        selector.clear(false);
        if (thisSelectorFilters.length) {
          if (selector.multiple) {
            const allValues = [];
            for (const filter of thisSelectorFilters) {
              const value = filter.column.options.find((option) => option.value.toString() === filter.value.toString());
              if (value) {
                allValues.push(value);
              }
            }
            if (allValues.length) {
              selector.selectOptions(allValues);
            }
          } else {
            const value = thisSelectorFilters[0].column.options.find((option) => option.value.toString() === thisSelectorFilters[0].value.toString());
            if (value) {
              selector.selectOption(value, false);
            }
          }
        } else {
          // selector.setSelected([]);
        }
      }
    });
  }

  /**
   * Store active filters in the url query params.
   */
  private updateUrlFilters(): void {
    if (this.options && this.options.disableUrlQueryFilters) {
      return;
    }
    let queryParams = Object.assign({}, this.route.snapshot.queryParams);
    if (!this._activeFilters.length && queryParams.tableFilters) {
      delete queryParams.tableFilters;
    }
    const groupedFilters = this.groupedActiveFilters;
    if (groupedFilters.length) {
      queryParams.tableFilters = {};
      for (const filter of groupedFilters) {
        let value = [];
        for (const child of filter.children) {
          value.push(child.value);
        }
        queryParams.tableFilters[filter.value] = encodeURI(value.join(','));
      }
      queryParams.tableFilters = JSON.stringify(queryParams.tableFilters);
    }
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams
    }).catch((error) => console.warn(error));
  }

  /**
   * Activate filters from the url query params.
   */
  private activateUrlFilters(): void {
    const tableFilters = this.tableService.currentFilters;
    if (!tableFilters) {
      return;
    }
    if (typeof tableFilters === 'object') {
      for (const filter in tableFilters) {
        if (tableFilters.hasOwnProperty(filter)) {
          const column = this.columns.find((column) => column.value === filter);
          if (!column) {
            continue;
          }
          let label: string|Array<string> = decodeURI(tableFilters[filter]);
          if (column.settings.type === 'Select' && column.options.length) {
            label = label.split(',');
            for (const option of label) {
              const columnOption = column.options.find((item) => item.value.toString() === option.toString());
              if (!columnOption) {
                continue;
              }
              this._activeFilters.push({
                column: column,
                label: columnOption ? columnOption.label : decodeURI(tableFilters[filter]),
                value: columnOption ? columnOption.value : decodeURI(tableFilters[filter]),
                type: column.settings.type,
                multiple: column.settings.multiple || false
              });
            }
          } else {
            this._activeFilters.push({
              column: column,
              label: label,
              value: decodeURI(tableFilters[filter]),
              type: column.settings.type,
              multiple: column.settings.multiple || false
            });
          }
        }
      }
      setTimeout(() => {
        this.updateSelectorsBasedOnFilters();
      });
    }
  }

  /**
   * Converts row data by different types into string.
   */
  public convertData(row: EromTableRowColumn|string|number, ignoreCut: boolean = false) {
    if (!row) {
      return 'n/a';
    }
    if (typeof row === 'string' || typeof row === 'number') {
      return this.sanitized.bypassSecurityTrustHtml(row.toString());
    }
    if (row.type === 'String') {
      let text;
      if (typeof row.text === 'object') {
        text = row.text ? Object.values(row.text).join(', ') : 'n/a';
      } else if (Array.isArray(row.text)) {
        text = row.text ? row.text.join(', ') : 'n/a';
      } else {
        text = row.text.toString();
      }
      if (row.settings && row.settings.maybeCutNeeded && !ignoreCut) {
        text = EromTableComponent.maybeStringCutNeeded(row, text);
      }
      return this.sanitized.bypassSecurityTrustHtml(text);
    }
    if (row.type === 'Date') {
      return this.sanitized.bypassSecurityTrustHtml(this.convertDateTime(row.text.toString(), '.', false));
    }
    if (row.type === 'DateTime') {
      return this.sanitized.bypassSecurityTrustHtml(this.convertDateTime(row.text.toString(), '.', true));
    }
    return this.sanitized.bypassSecurityTrustHtml(EromTableComponent.maybeStringCutNeeded(row, row.text.toString()));
  }

  /**
   * Converts dateTime into string with custom delimiter.
   * @return {String}
   */
  public convertDateTime(payload: string, delimiter: string, withTime: boolean = true): string {
    if (!payload) {
      return;
    }
    const date = new Date(payload);
    if (isNaN(date.getTime())) {
      return 'n/a';
    }
    const fullYear = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes().toString();
    const AMPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < '10' ? '0' + minutes : minutes;
    function toDoubleDigit (num) {
      return num.toString().length < 2 ? '0' + num : num.toString();
    }
    let result = toDoubleDigit(day) + delimiter + toDoubleDigit(month) + delimiter + fullYear;
    if (withTime) {
      result += ' ' + hours + ':' + minutes + ' ' + AMPM;
    }
    return result;
  }

  /**
   * Return object of column classes by data type.
   * @return {Object}
   */
  public columnClasses(column: EromTableColumn): object {
    return {
      'erom-table-head-search': column.settings.type === 'Search',
      'erom-table-head-select': column.settings.type === 'Select',
      'erom-table-head-order': column.settings.type === 'Order',
      'erom-table-head-actions': column.settings.type === 'Actions',
      'erom-table-head-checkbox': column.settings.type === 'Checkbox',
      'active': this._activeFilters.some((filter) => filter.column.value === column.value)
    };
  }

  /**
   * Activate or deactivate column head.
   * @return {Object}
   */
  public headColumnActioned(column: EromTableColumn, index: number): void {
    if (column.settings.type !== 'Search') {
      this._activeHeadColumn = this._activeHeadColumn === index ? null : index;
    }
  }

  /**
   * Add new filter or remove existing one from table active filters.
   */
  public activateFilter(event: any, column: EromTableColumn): void {
    const type = column.settings.type;
    const multiple = column.settings ? column.settings.multiple : false;
    if (type === 'Search') {
      const self = this;
      const topEvent = event;
      if (this._inputTimer) {
        window.clearTimeout(this._inputTimer);
      }
      this._inputTimer = window.setTimeout(() => {
        event = topEvent;
        const thisFilter = self._activeFilters.find((filter) => filter.column !== undefined && filter.column === column);
        if (thisFilter) {
          const thisIndex = self._activeFilters.findIndex((filter) => filter.column !== undefined && filter.column === column);
          if (event.target.value === '') {
            self._activeFilters.splice(thisIndex, 1);
          } else {
            self._activeFilters[thisIndex] = {
              label: event.target.value,
              value: event.target.value,
              column,
              type
            };
          }
        } else {
          const searchFilter = {
            label: event.target.value,
            value: event.target.value,
            column,
            type
          };
          self._activeFilters.push(searchFilter);
        }
        self.offset = 0;
        self.filtersChangeEvent.emit({
          ppp: self.ppp,
          offset: self.offset,
          limit: self.requestLimit,
          filters: self._activeFilters,
        });
        const activeColumn = self._activeHeadColumn ? self.columns[self._activeHeadColumn] : null;
        if (activeColumn && activeColumn.settings && typeof activeColumn.settings.multiple !== 'undefined' && activeColumn.settings.multiple === false) {
          self._activeHeadColumn = null;
        }
      }, this._inputTimeout || 400);
    } else if (type === 'Select') {
      this._activeFilters = this._activeFilters.filter((item) => item.column === null || item.column.value !== column.value);
      if (event.option === null) {
        return;
      }
      if (Array.isArray(event.option)) {
        for (let i = 0; i < event.option.length; i++) {
          const selectedOption = event.option[i];
          this._activeFilters.push({
            column: column,
            label: selectedOption.label,
            value: selectedOption.value,
            type: type,
            multiple: multiple
          });
        }
      } else {
        this._activeFilters.push({
          column: column,
          label: event.option.label,
          value: event.option.value,
          type: type,
          multiple: multiple
        });
      }
    }
    if (type !== 'Search') {
      this.offset = 0;
      this.filtersChangeEvent.emit({
        ppp: this.ppp,
        offset: this.offset,
        limit: this.requestLimit,
        filters: this._activeFilters
      });
      const activeColumn = this._activeHeadColumn ? this.columns[this._activeHeadColumn] : null;
      if (activeColumn && activeColumn.settings && typeof activeColumn.settings.multiple !== 'undefined' && activeColumn.settings.multiple === false) {
        this._activeHeadColumn = null;
      }
    }
  }

  /**
   * Find value of an already activated filter.
   */
  public activeFilterValue(column: EromTableColumn): string {
    const filterColumn = this._activeFilters.find((item) => item.column.value === column.value);
    if (filterColumn) {
      if (column.settings.type === 'Search') {
        return filterColumn.value.toString();
      }
    }
    return null;
  }

  /**
   * Activate the order type filter.
   */
  public activateOrder (column: EromTableColumn, order: string): void {
    this._activeFilters = this._activeFilters.filter((filter) => filter.type !== 'Order');
    const sort = {
      label: order,
      value: order,
      column: column,
      type: <const>'Order'
    };
    this._activeFilters.push(sort);
    this.offset = 0;
    this.filtersChangeEvent.emit({
      ppp: this.ppp,
      offset: this.offset,
      limit: this.requestLimit,
      filters: this._activeFilters,
      loadMore: false
    });
  }

  /**
   * Check if given order filter is active.
   * @return {boolean}
   */
  public isOrderActive(column: EromTableColumn, type: string): boolean {
    return this._activeFilters.some((filter) => filter.type === 'Order' && filter.column.value === column.value && filter.value === type);
  }

  /**
   * Check if given filter is active.
   * @return {boolean}
   */
  public isFilterActive(column: EromTableColumn): boolean {
    return this._activeFilters.some((filter) => filter.column.value === column.value);
  }

  /**
   * Load more data in the table initialization.
   * Data processing is made on the template that initialized the table component.
   */
  public loadMoreAction(): void {
    this.offset += this.requestLimit;
    this.filtersChangeEvent.emit({
      ppp: this.ppp,
      offset: this.offset,
      limit: this.requestLimit,
      filters: this._activeFilters,
      loadMore: true
    });
  }

  /**
   * Initialize the loading more action if load more div is viewport.
   */
  public infiniteLoadInit(event: any): void {
    if (event) {
      this.loadMoreAction();
    }
  }

  /**
   * Initialize bulk action on select.
   */
  public initializeBulkAction(event: any): void {
    if (event.option === null) {
      this._bulkAction = null;
      return;
    }
    this._bulkAction = {
      label: event.option.label,
      value: event.option.value
    };
  }

  /**
   * Submit bulk action.
   * Data processing is made on the template that initialized the table component.
   */
  public submitBulkAction(event: any): void {
    if (event === null) {
      return;
    }
    this.bulkActionInit.emit({
      bulkAction: this._bulkAction,
      bulkItems: this._bulkRowsSelected
    });
    this._tableRows = this._tableRows.map((r) => {
      return {
        ...r,
        selected: false
      };
    });
    const bulkSelector = this.selectors.find(s => s.id === 'bulkSelector');
    if (bulkSelector) {
      bulkSelector.reset();
    }
    this._bulkAction = null;
    this._bulkRowsSelected = [];
    this._bulkRowsSelectedAll = false;
    this._bulkRowsSelectedAllCount = 0;
  }

  /**
   * Emit event on table row was clicked.
   * Data processing is made on the template that initialized the table component.
   */
  public initializeTableRowAction(event: any, row: EromTableRow, action: EromTableActionInterface, tableRow: boolean): void {
    if (tableRow && event.target.nodeName !== 'TD' && event.target.nodeName !== 'SPAN' && event.target.nodeName !== 'DIV') {
      return;
    }
    this.tableItemActionEvent.emit({
      row,
      action,
      ctrl: event.ctrlKey || event.which === 2,
      which: event.which
    });
  }

  /**
   * Check or un-check all table rows.
   */
  public bulkActionCheckAll(event: any): void {
    const checked = event.target.checked;
    this._bulkRowsSelectedAll = event.target.checked;
    this._bulkRowsSelectedAllCount = 0;
    if (!checked) {
      for (const index in this._tableRows) {
        if (this._tableRows.hasOwnProperty(index)) {
          this._tableRows[index].selected = false;
          this._bulkRowsSelected = [];
        }
      }
    } else {
      this._bulkRowsSelectedAllCount = this.limit;
      for (const index in this._tableRows) {
        if (this._tableRows.hasOwnProperty(index)) {
          this._tableRows[index].selected = true;
          this._bulkRowsSelected.push(this._tableRows[index].id);
        }
      }
    }
  }

  /**
   * Check or un-check a single table row.
   */
  public bulkActionCheckSingleRow(event: any, row: EromTableRow): void {
    const checked = event.target.checked;
    const itemIndex = this._bulkRowsSelected.indexOf(row.id);
    this._bulkRowsSelectedAll = false;
    if (!checked) {
      this._bulkRowsSelected.splice(itemIndex, 1);
      this._bulkRowsSelectedAllCount--;
      row.selected = false;
    } else {
      this._bulkRowsSelected.push(row.id);
      this._bulkRowsSelectedAllCount++;
      row.selected = true;
    }
    if (this._bulkRowsSelectedAllCount === this.limit) {
      this._bulkRowsSelectedAll = true;
    }
  }

  /**
   * Clear specific filter from _activeFilters.
   */
  public clearFilter(filter: EromTableActiveFilter): void {
    const thisIndex = this._activeFilters.findIndex((item) => item.value === filter.value && item.column.value === filter.column.value);
    if (thisIndex > -1) {
      this._activeFilters.splice(thisIndex, 1);
    }
    this.offset = 0;
    this.filtersChangeEvent.emit({
      ppp: this.ppp,
      offset: this.offset,
      limit: this.requestLimit,
      filters: this._activeFilters
    });
  }

  /**
   * Clear all children of a specific filter group.
   */
  public clearGroupedFilter(group: EromTableFilterGroup): void {
    for (const filter of group['children']) {
      this.clearFilter(filter.originalFilter);
    }
  }

  /**
   * Clear all filters from _activeFilters.
   */
  public clearAllFilters(): void {
    this._activeFilters = [];
    this.offset = 0;
    this.filtersChangeEvent.emit({
      ppp: this.ppp,
      offset: this.offset,
      limit: this.requestLimit,
      filters: this._activeFilters
    });
  }

  /**
   * Emit event on add new row button pressed.
   */
  public addNewRow(): void {
    this.addNewRowEvent.emit();
  }

  /**
   * Emit event on csv export button pressed.
   */
  public csvExport(): void {
    this.csvExportEvent.emit({
      activeFilters: this._activeFilters
    });
  }

  /**
   * Emit event on rows export button pressed.
   */
  public rowsExport(): void {
    this.rowsExportEvent.emit({
      activeFilters: this._activeFilters
    });
  }

  /**
   * Emit event on rows import button pressed.
   */
  public rowsImport(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      this.rowsImportEvent.emit({
        data: JSON.parse(e.target.result.toString())
      });
    };
    reader.readAsText(event.target.files[0]);
  }

  /**
   * Mobile filters dropdown open/close.
   */
  public activateMobileFilters(): void {
    this._mobileFiltersActive = !this._mobileFiltersActive;
  }

  /**
   * Inverse DIV children order on mobile.
   */
  private inverseChildrenOrder(parentClass: string): void {
    if (!this.options || !this.options.mobileSwitchBreakpoint) {
      return;
    }
    const parent: HTMLElement = document.querySelector(parentClass);
    if (!parent) {
      return;
    }
    const orderChanged = parent.classList.contains('order-changed');
    if (this._windowWidth <= this.options.mobileSwitchBreakpoint) {
      if (orderChanged) {
        return;
      }
      for (let i = 0; i < parent.childNodes.length; i++){
        parent.insertBefore(parent.childNodes[i], parent.firstChild);
      }
      parent.classList.add('order-changed');
    } else if (orderChanged) {
      for (let i = 0; i < parent.childNodes.length; i++){
        parent.insertBefore(parent.childNodes[i], parent.firstChild);
      }
      parent.classList.remove('order-changed');
    }
  }

  /**
   * Return column label on desktop and column label plus counter of active sub-filters on mobile.
   */
  public tableHeadSelectTitle(column: EromTableColumn): string {
    if (this.tableShouldSwitchMobile) {
      const currentGroup = this.groupedActiveFilters.find((group) => group.value === column.value);
      if (currentGroup) {
        return column.label.toString() + ' (' + currentGroup.children.length + ')';
      } else {
        return column.label.toString();
      }
    } else {
      return column.label.toString();
    }
  }

  /**
   * Add new filter or remove existing one from table active filters.
   * @return {Array<EromTableFilterGroup>}
   */
  public get groupedActiveFilters(): EromTableFilterGroup[] {
    const grouped: EromTableFilterGroup[] = [];
    for (const filter of this._activeFilters) {
      const column = grouped.find((item) => item.value && item.value === filter.column.value );
      if (column) {
        column.children.push({
          label: filter.label,
          value: filter.value,
          originalFilter: filter
        });
      } else {
        grouped.push({
          label: filter.column.label,
          value: filter.column.value,
          children: [{
            label: filter.label,
            value: filter.value,
            originalFilter: filter
          }]
        });
      }
    }
    return grouped;
  }

  /**
   * Get option by option key.
   */
  public getOptionByKey(key: string): any {
    if (!this.options) {
      return false;
    }
    return this.options[key];
  }

  public toggleTableRow(event, id: string): any {
    this.toggleEvent.emit({
      id: id,
      checked: event.target.checked
    });
  }

  public hasTooltip(row, text: any): boolean {
    return row && row.settings && row.settings.maybeCutNeeded && text && text.toString().length > row.settings.maxChars;
  }

  /**
   * Get selected bulk action.
   */
  get bulkAction(): EromTableSelectOption {
    return this._bulkAction;
  }

  /**
   * Get active filters.
   */
  get activeFilters(): EromTableActiveFilter[] {
    return this._activeFilters || [];
  }

  /**
   * Are all rows selected.
   */
  get bulkRowsSelectedAll(): boolean {
    return this._bulkRowsSelectedAll || false;
  }

  /**
   * Get counter of all bulk rows selected.
   */
  get bulkRowsSelectedAllCount(): number {
    return this._bulkRowsSelectedAllCount || 0;
  }

  /**
   * Get bulk ticker icon class.
   */
  get bulkTickerIconClass(): string {
    if (this._bulkRowsSelectedAll) {
      return 'check';
    }
    if (this._bulkRowsSelectedAllCount > 0) {
      return 'minus';
    }
    return null;
  }

  /**
   * Check if mobileSwitchBreakpoint option is set in table options.
   */
  get tableShouldSwitchMobile(): boolean {
    if (!this.options || !this.options.mobileSwitchBreakpoint) {
      return false;
    }
    return this._windowWidth <= this.options.mobileSwitchBreakpoint;
  }

  /**
   * Check if mobile filters dropdown is open or not.
   */
  get mobileFiltersActive(): boolean {
    return this._mobileFiltersActive;
  }

  /**
   * Check if mobile filters dropdown is open or not.
   */
  get moreOptionsExpanded(): boolean {
    return this._moreOptionsExpanded;
  }

  public get tableRows(): EromTableRow[] {
    return this._tableRows;
  }

  /**
   * Listen the document escape keydown event.
   */
  @HostListener('document:keydown.escape', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void {
    this._moreOptionsExpanded = false;
  }

  /**
   * Listen the window resize event.
   */
  @HostListener('window:resize', ['$event'])
  public onDocumentResize(): void {
    this._windowWidth = window.innerWidth;
    this.inverseChildrenOrder('.erom-table-bulk-actions');
    this.inverseChildrenOrder('.erom-table-head-panel');
  }

  /**
   * Listen the document click event.
   */
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this._activeHeadColumn = null;
    }
    if (this._moreOptionsExpanded && !this.moreButtonsSelector.nativeElement.contains(event.target)) {
      this._moreOptionsExpanded = false;
    }
  }

}
