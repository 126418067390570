import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EromMessageInformerComponent } from './erom-message-informer.component';
import { MessageService } from './message.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    EromMessageInformerComponent
  ],
  providers: [
    MessageService
  ],
  exports: [EromMessageInformerComponent]
})
export class EromMessageInformerModule {}
