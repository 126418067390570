import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EromTableComponent } from './component/erom-table.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EromSelectModule } from '../erom-select/erom-select.module';
import { EromTableService } from './_services/erom-table.service';
import { InViewportModule } from 'ng-in-viewport';

@NgModule({
  declarations: [
    EromTableComponent
  ],
  exports: [
    EromTableComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    MatTooltipModule,
    EromSelectModule,
    InViewportModule
  ],
  providers: [
    EromTableService
  ]
})
export class EromTableModule {}
