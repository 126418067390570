import { Component, Input, OnInit, Output } from '@angular/core';
import { MenuItemInterface } from '../../_interfaces';
import { BreadcrumbsService } from '../../_services/breadcrumbs.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  /**
   * Custom non-route breadcrumbs.
   * @type {MenuItemInterface[]}
   */
  @Input() breadcrumbs: MenuItemInterface[];

  /**
   * Action buttons.
   * @type {Array}
   */
  @Input() actions: Array<any>;

  /**
   * Emit event for breadcrumb button action;
   * @type {Object}
   */
  @Output() buttonActioned = new EventEmitter<{action: string}>();

  constructor(
    private breadcrumbsService: BreadcrumbsService
  ) {
  }

  ngOnInit(): void {
  }

  public actionButton(event, button: any) {
    event.preventDefault();
    this.buttonActioned.emit({
      action: button.action
    });
  }

  get breadcrumbsItems(): MenuItemInterface[] {
    return this.breadcrumbs ? this.breadcrumbs : this.breadcrumbsService.breadcrumbs;
  }
}
