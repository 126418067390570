import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Message, MessageType } from './message.model';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { MessageService } from './message.service';

@Component({
  selector: 'erom-message-informer',
  templateUrl: './erom-message-informer.component.html',
  styleUrls: ['./erom-message-informer.component.scss']
})
export class EromMessageInformerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id = 'eromMessageInformer';
  @Input() fade: boolean;

  private _messages: Message[] = [];
  private _messageSubscription: Subscription;
  private _routeSubscription: Subscription;

  constructor(
    private elRef: ElementRef,
    private router: Router,
    private messageService: MessageService
  ) { }

  public ngOnInit(): void {
    this._messageSubscription = this.messageService.onMessage(this.id).subscribe(message => {
      if (!message.message) {
        this._messages = this._messages.filter(x => x.keepAfterRouteChange);
        this._messages.forEach(x => delete x.keepAfterRouteChange);
        return;
      }
      this._messages.push(message);
      if (message.autoClose) {
        setTimeout(() => this.removeMessage(message), parseInt(message.autoClose.toString(), 10));
      }
      window.scrollTo(0, 0);
    });
    this._routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.clear(this.id);
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // if (changes.message.currentValue !== changes.message.previousValue) {
    //   let offsetTop = 0;
    //   if (this.elRef.nativeElement && this.elRef.nativeElement.offsetTop) {
    //     offsetTop = this.elRef.nativeElement.offsetTop > 30 ? this.elRef.nativeElement.offsetTop - 30 : 0;
    //   }
    //   window.scrollTo(0, offsetTop);
    // }
  }

  public ngOnDestroy(): void {
    this._messageSubscription.unsubscribe();
    this._routeSubscription.unsubscribe();
  }

  public removeMessage(message: Message): void {
    if (!this._messages.includes(message)) {
      return;
    }
    if (this.fade) {
      this._messages.find(x => x === message).fade = true;
      setTimeout(() => {
        this._messages = this._messages.filter(x => x !== message);
      }, 250);
    } else {
      this._messages = this._messages.filter(x => x !== message);
    }
  }

  public cssClasses(message: Message): string {
    if (!message) {
      return;
    }
    const classes = ['message', 'message-dismissable'];

    const messageTypeClass = {
      [MessageType.Success]: 'message-success',
      [MessageType.Error]: 'message-danger',
      [MessageType.Info]: 'message-info',
      [MessageType.Warning]: 'message-warning'
    };

    classes.push(messageTypeClass[message.type]);

    if (message.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

  public iconClasses(message: Message): string {
    const classes = [];

    const messageTypeClass = {
      [MessageType.Success]: 'icon-success',
      [MessageType.Error]: 'icon-danger',
      [MessageType.Info]: 'icon-info',
      [MessageType.Warning]: 'icon-warning'
    };

    classes.push(messageTypeClass[message.type]);

    return classes.join(' ');
  }

  public get messages(): Message[] {
    return this._messages || [];
  }
}
