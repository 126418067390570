import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'erom-bulk-paste',
  templateUrl: './erom-bulk-paste.component.html',
  styleUrls: ['./erom-bulk-paste.component.scss']
})
export class EromBulkPasteComponent  {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() emptyPlaceholder: string;
  @Input() splitRegex: RegExp;
  @Input() set value(value: Array<string>) {
    if (this._tagsTouched || !value) {
      return;
    }
    this._tags = Object.values(value);
  }

  @Output() tagsUpdated = new EventEmitter<any>();

  private _tags: Array<string>;
  private _tagsTouched: boolean;

  constructor() {
    this.splitRegex = new RegExp(/[^A-Za-z0-9-_:]+/g);
    this._tags = [];
  }

  public createTags(event): void {
    this._tagsTouched = true;
    if (this.splitRegex.test(event.target.value)) {
      this.createTag(event);
    }
  }

  public createTag(event): void {
    event.preventDefault();
    event.stopPropagation();
    const tempTags = event.target.value.split(this.splitRegex).filter(tag => tag);
    if (tempTags.length) {
      this._tags = [...tempTags].concat(this._tags);
      this.tagsUpdated.emit({
        tags: this.tags
      });
      event.target.value = '';
    }
  }

  public removeTag(tag: string): void {
    const index = this._tags.indexOf(tag);
    if (index > -1) {
      this._tags.splice(index, 1);
      this.tagsUpdated.emit({
        tags: this.tags
      });
    }
  }

  public removeAllTags(): void {
    this._tags = [];
    this.tagsUpdated.emit({
      tags: this.tags
    });
  }

  public get isEmpty(): boolean {
    return !this._tags.length;
  }

  public get tags(): Array<string> {
    return this._tags;
  }

  public reset(event: boolean = true): void {
    this._tags = [];
    if (event) {
      this.tagsUpdated.emit({
        tags: this.tags
      });
    }
  }

}
