import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../../../src/app/app.module';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { environment } from '../environments/environment';
import { NgxStripeModule } from 'ngx-stripe';

@NgModule({
  declarations: [
    AppComponent,
    StripePaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule,
    SharedModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripe_publishable_key),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    translateService.use('en');
  }
}
