import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { markAsTouched } from '../../_helpers/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: UntypedFormGroup;

  private _returnUrl: string;
  private _submitting: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']).catch();
    }
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public ngOnInit(): void {
    this._returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || '/';
  }

  public ngOnDestroy(): void {
  }

  public onSubmit(): void {

    markAsTouched(this.loginForm);

    if (this.loginForm.invalid) {
      return;
    }

    this._submitting = true;

    this.authService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(data => {
        this._submitting = false;
        this.router.navigate([ this._returnUrl ]);
      }, error => {
        this._submitting = false;
        console.log(error);
      });
  }

  public get f(): any {
    return this.loginForm.controls;
  }

  public get submitting(): boolean {
    return this._submitting;
  }

}
