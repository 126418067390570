import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { API } from '../_store';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private api: API,
    private injector: Injector
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const authService = this.injector.get(AuthenticationService);
    const tokens = authService.tokens;
    const jwtHelper = new JwtHelperService();

    let clone = request;

    if (tokens && tokens.accessToken) {
      // if accessToken is expired update it using refreshToken
      if ( jwtHelper.isTokenExpired( tokens.accessToken ) && !request.url.endsWith( this.api.URLS.refreshToken ) ) {
        return authService.refreshAccessTokenInInterceptor(request, next);
      } else {
        clone = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${tokens.accessToken}` )
        });
      }
    }

    return next.handle(clone);
  }
}
