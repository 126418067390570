export class Message {
  id: string;
  type: MessageType;
  message: string;
  autoClose: boolean|number;
  keepAfterRouteChange: boolean;
  fade: boolean;

  constructor(init?: Partial<Message>) {
    Object.assign(this, init);
  }
}

export enum MessageType {
  Success,
  Error,
  Info,
  Warning
}
